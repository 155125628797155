.grid-2-column,
.grid-3-column,
.grid-4-column,
.grid-5-column,
.grid-1-2-column,
.grid-2-1-column {
    display: grid;
    justify-content: space-between;
    gap: 13px 26px;

    @include breakpoint(from-large) {
        gap: 16px 32px;
    }

    @include breakpoint(from-xlarge) {
        gap: 22px 42px;
    }

    @include breakpoint(to-medium) {
        justify-content: stretch;
    }

    .image-wrapper {
        padding: 12px;
        display: flex;
        justify-content: center;

        img {
            max-height: 62px;
        }
    }

    .content-wrapper {
        padding: 12px;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .flex {
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        flex-wrap: nowrap !important;

        &.gap {
            gap: 12px;
        }

        i {
            font-size: 24px;
            margin-bottom: 10px;
            margin-left: 2px;
            color: $base2-blue;
        }

        h4 {
            font-size: 16px;
            margin-right: 0;
            margin-left: 4px;
            color: $base2-blue;
        }
    }
    &.small-gap {
        gap: 10px 26px;
    }
    &.smallest-gap {
        gap: 10px;
    }
    &.legacy-gap {
        gap: 7px;
    }
    &.no-gap {
        gap: 0;
    }
    &.no-padding {
        gap: 30px;
    }
}

.grid-3-column {
    @include breakpoint(from-medium) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.grid-2-column {
    @include breakpoint(from-medium) {
        grid-template-columns: repeat(2, 1fr);
    }

    
}

.grid-4-column {
    @include breakpoint(from-medium) {
        grid-template-columns: repeat(4, 1fr);
    }
}
.grid-5-column {
    @include breakpoint(from-medium) {
        grid-template-columns: repeat(5, 1fr);
    }
}
.grid-1-2-column {
    @include breakpoint(from-medium) {
        grid-template-columns: 1fr 2fr;
    }
}
.grid-2-1-column {
    @include breakpoint(from-medium) {
        grid-template-columns: 2fr 1fr;
    }
}

ul.grid-layout {
    @include breakpoint(from-medium) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 36px;
    }
}

.centered {
    &.diagrams {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;

        @include breakpoint(to-medium) {
            flex-direction: column;
        }

        img {
            min-width: 45%;
            padding: 0 28px;

            @include breakpoint(from-medium) {
                max-width: 45%;
                margin-right: 10%;
                padding: 0;
            }

            &.saas-diagram {
                @include breakpoint(from-medium) {
                    margin-left: 8%;
                    padding: 0;
                    max-width: 34%;
                    margin-right: 6%;
                }

                min-width: 30%;
                padding: 0 68px;
            }
        }

        &.reverse {
            @include breakpoint(to-medium) {
                flex-direction: column-reverse;

                img {
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.inner-grid {
    &.white {

        i,
        p,
        h4 {
            color: $white;
        }
    }

    &.blue {
        background-color: $base2-blue;
    }

    &.aqua {
        background-color: $base2-aqua;
    }

    &.blue,
    &.aqua {
        padding: 30px;
        padding-right: 38px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        

        p,
        h2,
        h3,
        h4 {
            color: $white;
        }

        .cta_button,
        a.btn-primary {
            margin-top: 28px !important;

            &:hover {
                background: rgba($white, 0.3) !important;
            }
        }

        h2 {
            position: relative;
            margin-bottom: 28px;

            &:after {
                border-width: 0 0 2px;
                border-style: solid;
                border-color: $white;
                height: 1px;
                content: '';
                display: block;
                position: absolute;
                left: 0;
                padding-top: 22px;
                padding-top: 6px;
                width: 124px;
                opacity: 0.8;
            }
        }
    }
    &.no-padding {
        padding: 0;
    }
    &.box-shadow {
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    }
    &.justify-top {
        justify-content: flex-start;
    }

}
.smaller-width {
    @include breakpoint(from-medium) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

.bg-white {
    background-color: $white;
}

.padding {
    padding: 20px;

    @include breakpoint(from-medium) {
        padding: 50px;
    }
}
.small-padding {
    padding: 10px;

    @include breakpoint(from-medium) {
        padding: 20px;
    }
}