.circle-number-list {
    padding: 20px 0;
    list-style: none;
    margin-left: 0 !important;
    @include breakpoint(from-large) {
        width: 84% !important;
        margin: 0 auto !important;
        padding: 30px 0 40px;
    }
    &.offer-page {
        @include breakpoint(from-large) {
            width: 100% !important;
            padding: 20px 0 !important;
        }
    }
    li {
        list-style: none !important;
        margin: 0 !important;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px !important;
    }
    .number {
        display: block;
        float: left;
        clear: left;
        background-color: $base2-aqua;
        border: 1px solid $white;
        color: $white;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        margin-right: 16px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            margin-right: 0;
        }
    }
    .circle-number-text {
        @include breakpoint(from-large) {
            margin-right: 30px;
        }
    }
}

.sidebar-accordion.call-to-action.landing-page {
    @include breakpoint(to-medium) {
        display: none;
    }
}