.blocker {
	z-index: 999999;

	@include breakpoint(to-small) {
		padding: 8px;
	}

	.modal {
		text-align: center;

		@include breakpoint(from-medium) {
			max-width: 780px;
		}
		@include breakpoint(to-small) {
			padding: 15px;
			width: 96%;
		}
		p.small {
			padding-bottom: 16px;
			padding-top: 26px;
		}
		blockquote {
			@include breakpoint(to-small) {
				background-size: 30px;
				padding: 30px 10px 20px;
				margin: 10px 0;
				font-size: 16px;
				background-position: 0 0;
			}
		}
	}
}
