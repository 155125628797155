// Break Points
$mobile-width: 480px;
$tablet-portrait: 768px;
$tablet-landscape: 979px;
$desktop-width: 1040px;
$landscape: landscape;
$portrait: portrait;

// Navigation Breakpoint
$breakpoint-mobile-nav: 768px;

//Queries
@mixin mobile {
	@media screen and (min-width: 0) and (max-width: #{$mobile-width}) {
		@content;
	}
}
@mixin tablet-portrait {
	@media screen and (min-width: #{$mobile-width}) and (max-width: #{$tablet-portrait}) {
		@content
	}
}
@mixin tablet-landscape {
	@media screen and (min-width: #{$tablet-portrait}) and (max-width: #{$tablet-landscape}) {
		@content
	}
}
@mixin desktop {
	@media screen and (min-width: #{$tablet-landscape}) and (max-width: #{$desktop-width}) {
		@content
	}
}
@mixin responsive-fix {
    @media screen and (min-width: 0) and (max-width: #{$tablet-width}) {
        @content;
    }
}