.banner {
    padding: 0;
    margin: 0 auto;
    height: auto;
    z-index: 1;
    position: relative;
    line-height: 0; //Fix the background underhang from image
    width: 100%;

    &.inner {
        aspect-ratio: 2492/691;
        @include breakpoint(to-medium) {
        aspect-ratio: 7/4;
        object-fit: cover;
        }
        @include breakpoint(to-small) {
            aspect-ratio: 8/9;
            object-fit: cover;
        }
        &.case-study {
            aspect-ratio: auto;

            @include breakpoint(to-medium) {
                display: none;
            }
        }
    }
    @include breakpoint(to-medium) {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding-top: 42px;
        padding-bottom: 22px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @include breakpoint(to-medium) {
        flex-direction: column-reverse;
        aspect-ratio: 7/4;
    }

    @include breakpoint(to-small) {
        padding-top: 82px;
        aspect-ratio: 8/9;
        object-fit: cover;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;

        @include breakpoint(to-medium) {
            aspect-ratio: 3/2;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
        }

        @include breakpoint(to-small) {
            aspect-ratio: 8/9;
            object-fit: cover;

            &.case-study-header {
                aspect-ratio: 3/2;
            }
        }

        @include breakpoint(from-medium) {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 2;
            height: auto;
            display: inline;
            aspect-ratio: 2492/691;

            &.case-study-header {
                aspect-ratio: auto;
            }
        }

        

        &.aws-logo {
            aspect-ratio: auto;
        }
    }

    @include breakpoint(from-medium) {
        display: block;
    }
    .banner-text {
        width: 100%;
        margin: 0;
        z-index: 150;
        height: auto;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include breakpoint(from-medium) {
            padding-left: 5vw;
            position: absolute;
            bottom: 5vw;
            margin-top: 0;
        }

        @include breakpoint(to-medium) {
            padding: 8px;
            text-align: center;
            background: rgba(0, 0, 0, .7);
            border-radius: 5px;
        }

        h1 {
            font-size: 28px;
            line-height: 125%;
            //@extend %textshadow;
            padding-bottom: 4px;
            //letter-spacing: -2px;
            //text-align: center;
            color: $white;
            text-shadow: 1px 4px 8px rgba(0, 0, 0, 0.90);

            @include breakpoint(from-xlarge) {
                font-size: 52px;
            }

            &.landing-page {
                @include breakpoint(from-xlarge) {
                    font-size: 36px;
                }
            }

            @include breakpoint(to-medium) {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        h2 {
            font-size: clamp(20px, calc(1rem + 1vw), 22px);
            line-height: 125%;
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 22px;
            font-weight: normal;
            color: $white;
            text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.86);

            @include breakpoint(from-xlarge) {
                font-size: 28px;
            }

            @include breakpoint(to-medium) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        p {
            font-size: 13px;
            color: $white;
            line-height: 140%;
            text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.86);

            @include breakpoint(from-xlarge) {
                font-size: 17px;
                width: 75%;
            }

            @include breakpoint(to-small) {
                font-size: 12px;
            }
        }
    }

    .banner-home-logo {
        width: 180px;
        max-height: 118px;
        z-index: 9999;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;

        img {
            position: relative;
        }
        @include breakpoint(from-medium) {
            bottom: 22px;
            right: 10px;
            width: 220px;
            position: absolute;
            bottom: 16px;
            right: 16px;
        }

        @include breakpoint(from-large) {
            width: 296px;
            right: 16px;
            bottom: 28px;
        }

        @include breakpoint(from-xlarge) {
            bottom: 44px;
            width: 388px;
            right: 26px;
        }

        &.inner-page {
            @include breakpoint(from-medium) {
                bottom: 44px;
            }
        }
        img {
            z-index: 10;
            width: auto;
            max-height: 112px;
            display: block;
            @include breakpoint(to-medium) {
                max-height: 100px;
            }

            @include breakpoint(to-small) {
                display: none;
            }
        }
    }
}