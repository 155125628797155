  
  .cookie-bar {
    bottom: 0;
    position: fixed;
    vertical-align: top;
    width: 100% !important;
    max-width: 100% !important;
    background: $base2-aqua;
    z-index: 9998;
    padding: 20px;

    .container {
        position: relative;
        height: auto !important;
        display: flex;
        gap: 1em;
        flex-direction: column;
        padding: 0 !important;

        .buttons {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
            align-items: baseline;

            .button-group {
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
                gap: .5em;
            }
        }

    }

    p {
        color: $white;
        font-size: 90%;
        
        @include breakpoint(from-medium) {
            width: calc(100% - 200px);
        }
    }
  
    a {
        color: $white;
        text-transform: none;
        text-decoration: underline;
    }
  
    .close-cookie {
    display: flex;
    justify-content: center;
      color: $white;
      text-transform: uppercase;
      border-radius: 4px;
      padding: 0 24px;
      height: 30px;
      background-color: darken($base2-aqua, 5%);
      font-size: 90%;
      z-index: 9999;
      line-height: 30px;
      min-width: 100px;
      text-align: center;
      @extend %transition;
      text-decoration: none;
      min-width: 11em;


      &:hover {
          background-color: darken($base2-aqua, 10%);
          text-decoration: none;
      }
    }
  
    &.bar-hide {
     display: none;
    }
  }