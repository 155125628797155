// Typography
// Prevent text from resizing on roatation
html {
    -webkit-text-size-adjust: 100%;
    /* Prevent font scaling in landscape while allowing user zoom */
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }
  
  // Site base styles
  body {
    font-family: "Open Sans", Arial, sans-serif;
    font-style: normal;
    color: #454545;
    font-weight: 400;
    line-height: 142%;
    background-color: #f5f5f5;
    font-size: 14px;
  }
h1,
h2,
h3,
h4,
h5 {
    font-family: 'HelveticaNeue', 'Arial', sans-serif;
    font-weight: 600;
    color: $base2-heading;
    line-height: 115%;
    //@extend %textshadow-white;
    .de-em {
        font-weight: 300;
    }
    &.no-after {
        &:after {
            display: none !important;
        }
    }
    a {
        color: $base2-aqua;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
        &.white {
            color: $white;
        }
    }
    &.section-title {
        position: relative;
        margin-bottom: 18px;
        display: inline-block;
        width: auto;
        &:after {
            @include underlineleft;
            padding-top: 6px;
            border-width: 0 0 4px;
            width: 86%;
            border-color: $base2-heading;
            opacity: 0.6;
        }
        &.white {
            color: $white;
            &:after {
                border-color: $white;
            }
        }
    }
    &.promo-block {
        padding: 0 80px;
        line-height: 125%;
        font-weight: 300;
        color: $base2-green;
        margin: 26px 0;
        text-align: center;
        font-family: "Open Sans", Arial, serif;
    }
    &.white {
        color: $white;
        a {
            color: $white;
        }
    }
}

h1 {
    font-size: clamp(30px, calc(1rem + 1vw), 36px);
}

h2 {
    font-size: clamp(22px, calc(1rem + 1vw), 26px);
    margin-bottom: 14px;
    margin-top: 6px;
    &.section-title {
        font-size: clamp(28px, calc(1rem + 1vw), 34px);
    }
    &.solo {
        margin-top: 30px;
    }
}

h3 {
    font-size: clamp(18px, calc(1rem + 1vw), 22px);
    margin-bottom: 14px;
    margin-top: 6px;
    &.title {
        text-transform: capitalize;
        &.footer {
            text-shadow: none;
            text-transform: uppercase;
        }
    }
    &.section-title {
        margin-top: 8px;
    }
    &.event-heading {
        display: inline-block;
        border-bottom: 4px solid rgba(124, 205, 204, 0.8);
        margin-bottom: 22px;
        padding-bottom: 8px;
        text-transform: uppercase;
        clear: both;
        margin-top: 0;
    }
}

h4 {
    font-size: clamp(16px, calc(1rem + 1vw), 18px);
    margin-bottom: 12px;
    &.home {
        margin-top: 20px;
    }
    &.feature-heading {
        display: inline-block;
        border-top: 1px solid $light-grey;
        margin-top: 12px;
        margin-bottom: 12px;
        padding-top: 12px;
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
    }
}

h5 {
    font-weight: 600;
    font-size: 16px;
    color: $dark-grey;
    a {
        color: $dark-grey;
    }
}

// Styles
small {
    font-size: 15px;
    color: $mid-grey;
    font-style: italic;
    &.white {
        color: $white;
        opacity: 0.7;
    }
    &.date {
        font-size: 13px;
        margin-bottom: 8px;
        display: block;
    }
}

b,
strong,
.bold {
    font-weight: 700;
}

i,
.italic {
    font-style: italic;
}

// Paragraph rules
p.excerpt {
    margin-top: 16px;
}

p.event-address {
    white-space: pre-line;
    padding-bottom: 0;
    position: relative;
    margin-top: -20px;
}

p.event-address::first-line {
    font-weight: bold !important;
}

// inline-block fix
.clear-inline {
    display: block;
}

// code
pre {
    background-color: #f8f8f8;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    margin: 10px 0 20px;
    padding: 20px;
}

code {
    font-family: monospace;
    font-size: 14px;
    line-height: 1.4;
}

blockquote {
    margin: 20px 0;
    font-style: italic;
    font-size: 18px;
    line-height: 140%;
    color: $base2-green;
    font-weight: 400;
    padding: 30px 60px;
    background-image: url("../images/blockquote.svg");
    background-position: 0 16px;
    background-repeat: no-repeat;
    background-size: 50px;
    &.carousel {
        padding: 0;
    }
    &.testimonial-quote {
        padding: 0;
        background: none;
        font-size: inherit;
        font-style: normal;
        background: $light-grey;
        position: relative;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: stretch;
        padding: 26px;
        flex: 1;
        flex-grow: 1;
        color: $dark-grey;
        

        &:after,
        &:before {
            top: 100%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-top-color: $light-grey;
            border-width: 10px;
            left: 10%;
            margin-left: -10px;
        }

        &:before {
        border-top-color: rgba(0, 0, 0, 0.01);
        border-width: 11px;
        left: 90%;
        margin-left: -11px;
        }
		small {
			margin-top: auto;
		}
		p {
			display: flex;
			align-items: center;
		}
		span {
			text-align-last: left;
			margin-top: 8px;
			color: $dark-grey;
		}
	}
    @include breakpoint(to-small) {
        padding-right: 10px;
    }
    span {
        color: $mid-grey;
        text-align: right;
        width: 100%;
        display: inline-block;
    }
    &.base2-aqua {
        background: darken($base2-aqua, 4);
        color: $white;
        p {
            color: $white;
            font-size: 110%;
            line-height: 1.4;
        }
        &:after {
            border-top-color: darken($base2-aqua, 4);
        }
    }
}

// Links
a {
    color: $base2-aqua;
    text-decoration: none;
    @extend %transition;
    position: relative;
    &:hover {
        color: $base2-blue;
        cursor: pointer;
    }
    // Partner Images
    &.partner-link {
        margin-bottom: 36px;
        display: block;
    }
    &.position {
        padding: 6px 0;
        &:after {
            position: absolute;
            top: 9px;
            @include font-awesome("\f105");
            z-index: 20;
            left: 100%;
            margin-left: 6px;
            display: block;
        }
    }
    &.more-link {
        float: right;
        @extend %transition;
        &:after {
            position: absolute;
            top: 3px;
            @include font-awesome("\f105");
            z-index: 20;
            left: 100%;
            margin-left: 6px;
            display: block;
        }
        &.none {
            float: none;
        }
    }
    &.white {
        color: $white;
    }
}

// Button Link
a {
    // Button Link
    &.btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 250px;
        margin: 10px 0;
        padding: 14px 32px 14px 32px;
        color: $white;
        font-size: 110%;
        font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
        background-color: $base2-aqua;
        border: 2px solid $base2-aqua;
        @extend %transition;
        border-radius: 5px;
        position: relative;
        z-index: 5;
        &:hover {
            background-color: $base2-blue;
            color: $white;
            border: 2px solid $base2-blue;
        }
        &.white {
            background: none;
            border: 2px solid $white;
            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }
        }
        &.fade,
        &.fade-cta {
            background: $light-grey;
        }
        &.outline {
            background: none;
            color: #a2a2a2;
            border-color: #a2a2a2;
            &:hover {
                color: #767676;
                border-color: #767676;
            }
        }
        &.outline-white {
            background: none;
            color: $white;
            border-color: $white;
            &:hover {
                color: $white;
                border-color: $white;
                background-color: rgba($white, 0.3);
            }
        }
    }
}

// Rules
hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 26px 0;
}

// Tags
.tags {
    font-size: 10px;
    text-transform: uppercase;
    .tag {
        &:hover {
            text-decoration: underline;
        }
        &:nth-child(even) {
            color: $dark-grey;
        }
    }
}

// Icon
i {
    position: relative;
    &.tick {
        display: block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        &:after {
            position: absolute;
            top: 4px;
            @include font-awesome("\f00c");
            z-index: 20;
            left: 0;
            margin-left: 0;
            display: block;
        }
    }
}

// Icon Font
.column-icon {
    font-size: 40px;
    color: $base2-aqua;
    display: inline-block;
    float: left;
    position: relative;
    left: -16px;
    top: -8px;
    line-height: 100%;
    margin-right: -16px;
    margin-bottom: -8px;
    height: 20px;
    @include breakpoint(from-medium) {
        top: -2px;
        height: 40px;
    }
    @include apply-to-IE {
        top: 30px;
        height: 20px;
    }
    @include ie-edge {
        top: 30px;
        height: 20px;
    }
}

.win.chrome,
.win.safari {
    .three-column-layout {
        .column-icon {
            top: 89px;
        }
    }
}

.win.gecko {
    .three-column-layout {
        .column-icon {
            top: 32px;
        }
    }
}