/*  Base Styles */
$mobile_nav_text: #FFF;
$mobile_top_level: $dark-grey;
$mobile_top_level_current: $base2-aqua;
$mobile_top_level_hover : $base2-blue;
$mobile_top_level_border_bottom : none;
$mobile_second_level: $base2-aqua;
$mobile_second_level_hover: $base2-aqua;
$desktop_nav_text: #ababab;
$desktop_nav_text_hover: #ababab;
$desktop_top_level: inherit;
$desktop_top_level_current: $base2-aqua;
$desktop_top_level_hover : $base2-blue;
$desktop_top_level_border: none;
$desktop_second_level: $white;
$desktop_second_level_hover: $base2-aqua;

#nav-container {
	text-align: right;
}

// Mobile Navigation
// Hamburger Nav
#mobile-hamburger {
	text-transform:uppercase;
	position: absolute;
	z-index: 100;
	right: 0;
	top: 30px;
	height: 26px;
	width: 26px;
	display: block;
	padding: 0 20px;
	line-height: 50px;
	//background: $mobile_top_level_border_bottom;
	//color: $mobile_nav_text;
	//cursor: pointer;
	//border-bottom: 1px solid $mobile_top_level_border_bottom;

	@include breakpoint(from-medium) {
		right: 20px;
	}

	@include breakpoint(from-xlarge) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	// Add hamburger slides
	$width: 26px;
	$height: 3px;

	&:before,
	&:after,
	span {
		background-color: $desktop_nav_text_hover;
		content: '';
		display: block;
		width: $width;
		height: $height;
		position: absolute;
		right: 20px;
		top: 0px;
		//Animation Timing
		-webkit-transition: none 0.4s ease 0.4s;
		transition: none 0.4s ease 0.4s;
		-webkit-transition-property: transform, top, bottom, right, opacity;
		transition-property: transform, top, bottom, right, opacity;
	}
	&:before {
		top: 0px;
	}
	&:after {
		top: 16px;
	}
	span {
		top: 8px;
	}
}

// Animation Sequence
html.mm-opening {

	#mobile-hamburger {

		position: absolute;
		right: 0;

		span {
			left: 10px;
			opacity: 0;
		}
		&:before {
			transform: rotate( 45deg );
			-webkit-transform: rotate( 45deg );
			top: 10px;
		}
		&:after {
			transform: rotate( -45deg );
			-webkit-transform: rotate( -45deg );
			top: 10px;
		}
	}
}

// FOUC Fix for mmenu
#mobile-nav {
	display: none;

	    &.mm-menu {
	    	display: block;
	    }
		&:not(.mm-menu) {
		   display: none;
		}

	@include apply-to-IE {
		opacity: 0;

		&.mm-opened {
			opacity: 1;
		}
	}
}

// Main Full Width Navigation
.navigation {
	display: none;
    transition: all 0.3s ease-in;
    position: absolute;
    text-align: center;
    height: 30px;
    z-index: 2;
    margin-top: 20px;
    right: 40px;

    @include breakpoint(from-xlarge) {
    	display: block;
    }

    ul {
    	margin-top: 0px;
    }
    li {
	    margin: 0;
	    padding: 0;
	    display: inline-block;
	    float: left;
	    line-height: 40px;
	    position: relative;
	    top: 0px;
	    transition: all 0.3s ease-in;

	    a {
		    display: inline-block;
		    padding: 0 16px;
				z-index: 2;
				overflow: hidden;
				text-decoration:none;
				font-size: 12px !important;
				text-transform: uppercase;
				font-weight: 400;
				color: $dark-grey;

		    &:hover {
		    	color: $mobile_nav_text;
		    	background-color: $mobile_second_level;
		    	text-decoration: none;
		    }
		}
		&:hover > a {
			background-color: $mobile_second_level;
			color: $white;

			&:after {
					opacity: 1;
					color: $white;
				}
		}
		&.active,
		&.current {
			a {
		    	color: $base2-aqua;
			}
			&:hover a {
				color: $white;
			}
		}
		&.contact {
			a {
				padding: 0 28px;
				margin: 0;
				border-radius: 40px;
				border: 1px solid $base2-aqua;
				color: $base2-aqua;
				font-weight: 600;

				&:hover {
					color: $white;
				}
			}
		}

		// Drop Down
		&.has_children {
		    position: relative;
		    z-index: 2000;

		    a {
		    	padding-right:20px;

		    	&:after {
					position:absolute;
					right:5px;
					top: 14px;
					@include font-awesome("\f107");
					z-index: 20;
					color: $desktop_nav_text_hover;
					opacity:0.6;
				}

				&:hover:after {
					opacity: 1;
					color: $white;
				}
			}
		}
		&.has_children {

			ul.children {
			    display: none;
			    position: absolute;
			    left: 0;
			    width: 240px;
			    z-index: 9999!important;
			    background: $desktop_nav_text_hover;
			    top: 40px;
			    opacity: 0;

			    li {
				    width: 100%;
				    margin: 0;
				    padding: 0;
				    height: auto;
				    display: block;
				    float: none;

				    &:last-child {
				    	border-bottom: none;
				    }
				}
				li a {
					font-size: 12px;
					text-transform: capitalize;
				    display: block;
				    line-height: 20px;
				    border-bottom: 1px solid $base2-aqua;
					padding: 8px 0 8px 18px;
				    width: 100%;
				    text-align: left;
				    height: auto;
				    position: relative;
   		    		background: $mobile_second_level;
			    	color: $white;

		    		&:after {
						display: none;
					}
					&:hover {
						color: $white;
						background: $base2-blue;
					}
				}
			}
			&:hover .children {
				display: block;
				opacity: 1;
				-webkit-transition: all .5s ease-in-out;
				-moz-transition: all .5s ease-in-out;
				-ms-transition: all .5s ease-in-out;
				transition: all .5s ease-in-out;
			}
		}
		&.header-search {
			position: relative;
			height: 40px;

			a {
				padding: 0 10px;
				position: relative;
				display: block;

				i {
					font-size: 20px;

					&:before {
						line-height: 40px;
					}
				}
			}

			&:hover {
				.header-search-wrapper {
					display: block;
				}
			}

			.header-search-wrapper {
				background: $base2-aqua;
				position: absolute;
				display: none;
				width: 350px;
				right: 0;
				top: 40px;
				z-index: 99;
				padding: 20px;

				.search {
					margin: 0;

					#tipue_search_input {
						border: none;
						border-right: 2px solid $base2-aqua;
					}

					#search-submit {
						background: white;
						color: $base2-aqua;
					}
				}
			}
		}
	}
}
