.home.testimonial {
    .testimonial-quote {
        p {
            min-height: 130px !important;
        }
    }
}

.three-column-layout.video {
    a {
        h4 {
            color: $base2-aqua;
        }
    }

    @include breakpoint(from-medium) {
        margin-bottom: 0;
    }
}
