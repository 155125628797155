
// Main Content Area
#content {
  margin-top: 0px;
  position: relative;

  @include breakpoint(to-medium) {
    padding: 6px;
    width: 100%;
  }
  .container {
    max-width: $content_width;
    @extend %clearfix;
    @extend %grid-container-collapse;
    background-color: #fff;
    margin: 0 auto 10px;
    padding: 20px;

    @include breakpoint(from-medium) {
      padding: 50px;
      margin: 30px auto 28px;
    }

    &.no-padding {
      padding: 0;
    }
    &.no-margin {
      margin-top: 0;
      margin-bottom: 0;
    }
    &.no-bg {
      background: none;
    }

    &.no-background {
      background: none;
      padding-top: 12px;
      padding-bottom: 12px;

      @include breakpoint(from-medium) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    //home page video embed
    &.video {
      padding: 0;

      .video-embed-container {
        padding: 0;
      }
    }

    // Fallback for news post where there is no lead in
    &.content-news {
      @include breakpoint(from-medium) {
        margin-top: -30px;
        position: relative;
        z-index: 25;
      }

      em {
        font-style: italic;
      }
    }

    &.content-case-study,
    &.article-post {
      @include breakpoint(from-medium) {
        margin-top: -30px;
        position: relative;
        z-index: 25;

        .sidebar {
          margin-top: 100px;
        }

        &.no-banner-image {
          margin-top: 30px;
        }
      }

      .aside {
        position: relative;
      }

      .flex {
        @include breakpoint(from-medium) {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row-reverse;
        }
      }

      .section-title {
        clear: both;
      }

      .section-title {
        margin-bottom: 50px;
        color: $dark-grey;

        &.no-margin {
          margin-bottom: 0;
        }

        @include breakpoint(to-medium) {
          margin-bottom: 30px;
        }
      }

      h4 {
        font-size: 20px;
        margin: 50px 0 16px 0;
      }

      h5 {
        color: #656565;
        margin-bottom: 12px;
      }

      p,
      li {
        color: $case-study-grey;
      }
    }

    // Colour background options for containers
    &.base2-aqua {
      background-color: $base2-aqua;
    }

    &.base2-green {
      background-color: $base2-green;
    }

    &.base2-blue {
      background-color: $base2-blue;
    }

    &.base2-lime {
      background-color: $base2-lime;
    }

    &.base2-turquoise {
      background-color: $base2-turquoise;
    }

    // Inner Page Styles
    &.inner {
      p {
        margin-bottom: 22px;

        @include breakpoint(small) {
          // padding-left: 30px;
        }
      }

      ul {
        margin: 0 0 22px 20px;

        li {
          list-style: square;
          padding: 3px 0;
          list-style-position: outside;
        }

        ul,
        ul ul {
          margin-bottom: 0;
        }

        // Customer/Partners Section
        // &.customers {
        //   margin: 52px 0 22px;

        //   li {
        //     list-style-type: none;
        //     list-style-position: outside;
        //     margin-bottom: 42px;
        //     float: left;

        //     &.span_4 {
        //       @include breakpoint(from-medium) {
        //         @include grid(33);
        //         margin-bottom: 20px;

        //         h3,
        //         h4,
        //         p {
        //           margin-right: 30px;
        //         }
        //       }
        //     }

        //     &.span_3 {
        //       @include breakpoint(from-medium) {
        //         @include grid(25);
        //         margin-bottom: 40px;

        //         h3,
        //         h4,
        //         p {
        //           margin-right: 30px;
        //         }
        //       }
        //     }

        //     h3 {
        //       margin-bottom: 16px;
        //       margin-top: 20px;
        //     }
        //   }

        //   > li.span_4:nth-child(4),
        //   > li.span_4:nth-child(3n + 1) {
        //     margin-left: 0;
        //   }

        //   // Partners list
        //   &.partners {
        //     @include breakpoint(from-medium) {
        //       li {
        //         @include grid(50);

        //         p {
        //           margin-right: 50px;
        //         }
        //       }
        //     }
        //   }
        // }

        // // Community Page
        // &.columns {
        //   margin: 30px 0;

        //   li {
        //     list-style-type: none;
        //     list-style-position: outside;
        //     margin-bottom: 0;
        //   }

        //   .column-thumb {
        //     height: 250px;
        //     width: 100%;
        //     background-position: 50% 50%;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     display: block;
        //     margin: 10px auto 14px;
        //     @extend %transition;
        //     background-color: $base2-aqua;

        //     &:hover {
        //       opacity: 0.6;
        //       cursor: pointer;
        //     }
        //   }

        //   img {
        //     max-width: 100%;
        //     margin-bottom: 24px;
        //     height: auto;
        //   }

        //   h4 {
        //     margin-bottom: 10px;
        //   }

        //   p {
        //     margin-bottom: 16px;
        //   }

        //   .tags {
        //     font-size: 10px;
        //     text-transform: uppercase;

        //     .tag {
        //       &:hover {
        //         text-decoration: underline;
        //       }

        //       &:nth-child(even) {
        //         color: $dark-grey;
        //       }
        //     }
        //   }
        // }

        // Customers Page - Client Type Categories
        &.categories {
          padding: 0;
          margin: 0;

          li {
            list-style-type: none;
            list-style-position: outside;
            float: left;
            padding: 0 10px 0 0;
            margin-left: 10px;
            border-right: 1px solid $mid-grey;
            line-height: 100%;

            &:last-child {
              border-right: none;
            }

            &:first-child {
              margin-left: 0;
              border-right: none;
              padding-right: 2px;
            }

            a {
              font-weight: 600;
              font-style: italic;
            }
          }
        }

        // Team page
        &.team {
          margin-left: 0;
          padding-left: 0;
          margin-top: 40px;

          li {
            list-style-type: none;
            list-style-position: outside;
            padding: 0;
            margin: 0 0 40px;
            clear: left;

            img {
              max-width: 100%;
              margin: 0 0 20px;
              @extend %dropshadow;

              @include breakpoint(from-medium) {
                max-width: 100%;
                float: left;
                margin-right: 40px;
                margin-bottom: 40px;
              }
            }

            // Social links
            .social-media {
              float: none;
              margin: 16px 0 10px;
            }

            // Name
            h4 {
              margin-bottom: 8px;
              color: $base2-aqua;
            }

            // Position title
            .role {
              color: $mid-grey;
              font-style: italic;
              margin-bottom: 10px;
            }
          }
        }

        // Values
        &.values-column {
          margin-left: 0;
          padding-left: 0;

          li {
            list-style: none;
            margin-bottom: 16px;
            float: left;

            @include breakpoint(from-medium) {
              @include grid(33);

              p {
                margin-right: 30px;
              }
            }
          }
        }

        // Landing Pages
        &.landing {
          margin-left: 0;
          padding-left: 0;
          margin-top: 30px;

          li {
            list-style-type: none;
            list-style-position: outside;
            padding: 0 0 30px;
            margin: 0 0 30px;
            display: block;
            height: auto;
            border-bottom: 1px solid $light-grey;

            &:last-child {
              border-bottom: none;
            }

            .media-object {
              display: block;
              height: auto;
              width: 100%;
              margin-bottom: 20px;
              text-align: center;

              @include breakpoint(from-medium) {
                max-width: 24%;
                margin-right: 8%;
                float: left;
                clear: left;
                margin-bottom: 0;
              }

              img {
                max-width: 100%;
                height: auto;
                display: block;
                @extend %dropshadow;
              }
            }

            .post-excerpt {
              @include breakpoint(from-medium) {
                max-width: 68%;
                float: left;

                &.full {
                  max-width: 100%;
                }
              }
            }

            .more-link {
              float: none;
            }
          }
        }

        &.small-list {
          margin-left: 0;

          li {
            list-style: none;
            clear: both;
            padding: 8px 0;

            .post-info {
              color: $mid-grey;
              display: block;

              @include breakpoint(from-medium) {
                float: left;
                width: 80px;
              }
            }

            .post-title {
              float: left;
              display: inline-block;
            }
          }

          &.flex {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 20px;

            @include breakpoint(to-medium) {
              display: block;
            }

            li {
              width: 30%;
              padding: 0;
              margin: 0;

              &:nth-child(4) {
                display: none;
              }

              @include breakpoint(to-small) {
                width: 100%;
                margin-bottom: 10px;
              }

              h5 {
                margin-bottom: 10px;
              }

              p {
                margin-bottom: 0;
              }

              img {
                height: auto;
              }

              a {
                width: 100%;
                margin-right: 0;
                display: block;

                &:hover {
                  img {
                    opacity: 0.6;
                    @extend %transition;
                  }
                }

                img {
                  width: 100%;
                  margin-bottom: 10px;
                }

                &.article-read-more {
                  display: block;
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }

      ol {
        margin: 0 0 22px 20px;

        li {
          list-style-type: decimal;
          padding: 3px 0;
          list-style-position: outside;
        }

        ol,
        ol ol {
          margin-bottom: 0;
        }
      }
    }

    // Sidebar class for container and inner
    &.with-sidebar {
      padding: 0;
      background: none;
      position: relative;

      @include breakpoint(to-medium) {
        display: flex;
        flex-direction: column;

        .right,
        .sidebar {
          order: 2;
          padding-top: 24px;
        }
        .right,
        .aside {
          order: 1;
        }

      }
      &.tech {
        .right,
        .sidebar {
          padding-top: 0;
        }
        .sidebar-accordion.call-to-action {
          @include breakpoint(to-medium) {
            .accordion-content {
            display: block;
            }
          }
        }
      }
    }

    .asset-download-image {
      width: 40%;
      padding: 0 60px 0 0;
      float: left;

      @include breakpoint(to-small) {
        width: 100%;
        padding: 0;
        float: none;
        text-align: center;
      }

      img {
        height: auto;
        border: 1px solid #f2f2f2;
        box-shadow: 0 8px 6px -6px #666;
        margin-bottom: 30px;
        width: 100%;
      }
    }

    .asset-download-info {
      width: 60%;
      float: right;

      @include breakpoint(to-small) {
        float: none;
        width: 100%;
      }
    }
  }
}

// Hide on mobile
p.mobile-hide {
  @include breakpoint(to-medium) {
    display: none;
  }
}

a.sharelink {
  margin-left: 50px;
  text-transform: uppercase;
  font-size: 11px;
  height: 36px;
  line-height: 36px;
  margin-top: 6px;
  display: inline-block;

  i {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }
}

a.button.link.download {
  float: left;
}

.final-row {
  border-top-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $base2-blue;
  border: 1px solid $base2-blue;

  @include breakpoint(to-medium) {
    padding: 12px;
  }

  &.mobile {
    @include breakpoint(from-medium) {
      display: none;
    }
  }

  &.three-column {
    @include breakpoint(to-medium) {
      display: none;
    }

    display: grid;
    grid-template-columns: 52px 1fr 1fr 1fr;

    div {
      display: flex;
      align-items: center;
      border-left: 1px solid rgba($white, 0.4);
      padding: 12px;
      justify-content: center;

      &.no-border {
        border-left: none;
      }
    }

    h3 {
      font-size: 16px;
    }
  }

  h4,
  h3 {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    color: $white;
  }

  &.blue {
    p {
      color: $white;
      font-size: 17px;
      margin-bottom: 0 !important;
      margin-top: 16px;
      text-align: center;
    }
  }
}

.offer-buttons-wrapper {
  display: flex;
  flex-direction: column;

  .cta_button {
    &:hover {
      border-color: rgb(0, 190, 205) !important;
    }
  }

  .aws-button {
    .cta_button {
      &:hover {
        color: $base2-blue !important;
        border-color: $base2-blue !important;
      }
    }
  }
}
