.features{
    
    .feature{
        background-color: inherit;
        display: flex;
        text-align: left;
        font-size: 14px;
        line-height: 1.55;
        gap: 6px;
        &:before {
            content: "\f00c ";
            display: block;
            font-family: FontAwesome;
            
            //color: #007388;
            position: relative;
            top: 0px;
        }
    }
}

@media (max-width: 768px) {
    .features{
        padding-top: 14px;
        border-top: 2px solid rgba(255, 255, 255, 0.5);
        color: #fff;
        .feature{
            font-size: 16px;
        }
    }


}