$content_width:1224px;

#footer {
    background: #333333;
    padding: 60px 0 20px;
    color: #A2A2A2;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    .container {
        max-width: $content_width;
        margin: 0 auto;
        @extend %clearfix;
        padding: 0 20px;
        @include breakpoint (from-large) {
            padding: 0
        }
        .footer-menu {
            display: grid;
            @include breakpoint(from-small) {
                grid-template-columns: repeat (2, 1fr);
            }
            @include breakpoint(from-medium) {
                grid-template-columns: (1.5fr 1fr 1.2fr 1fr 0.8fr);
                gap: 0 22px;
                justify-content: space-between;
            }
        }
        &.subfooter {
            grid-template-columns: 1fr;
        }
        @include breakpoint(to-medium) {
            .col {
                margin-bottom: 22px;
            }
        }
    }
    .certification-logos {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px 0;
        margin-bottom: 16px;
        flex-direction: column;
        @include breakpoint(to-medium) {
            justify-content: flex-start !important;
            flex-direction: row;
            gap: 20px;
        }
    }
    h3 {
        font-size: clamp(16px, calc(1rem + 1vw), 18px);
        color: #bbb;
        color: rgba(187, 187, 187, 0.6);
        margin-bottom: 12px;
    }
    a {
        color: #999;
        color: rgba(153, 153, 153, 0.6);
        font-size: 12px;
        @extend %transition;
        &:hover {
            color: rgba(153, 153, 153, 1);
        }
    }
    li {
        padding: 2px 0;
        list-style-type: none;
    }
    // Subfooter - Copywrite
    .subfooter {
        border-top: 1px dotted #3E3E3E;
        border-top: 1px dotted rgba(255, 255, 255, 0.1);
        margin-top: 32px;
        padding-top: 14px;
        color: rgba(255, 255, 255, 0.2);
        font-size: 11px;
        clear: both;
        a {
            font-size: 11px;
            color: rgba(255, 255, 255, 0.2);
            text-decoration: none;
            margin-right: 8px;
            padding-left: 8px;
            &:hover {
                text-decoration: underline;
                color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

.jasanz {
    position: relative;
    top: 5px;
}

#footer #toplink {
    margin-top: 50px;
    text-align: center;
}

#footer #toplink a:hover {
    text-decoration: none;
    color: #ffffff;
}

#footer #toplink i {
    color: #55a79a;
}

#footer .container ul {
    position: relative;
    list-style: none;
    margin: 0px;
}

#footer .social-media a {
    text-decoration: none;
    color: #eeeeee;
    display: inline-block;
    line-height: 36px;
    width: 36px;
    height: 36px;
}

#footer .social-media a i {
    font-size: 20px;
    color: #ffffff;
    padding: 0;
    line-height: 36px;
    width: 36px;
    height: 36px;
    text-align: center;
    margin-right: 0.2em;
}

// Adding Footer Social
#footer .social-media {
    display: flex;
    justify-content: flex-start;
    float: none;
}

#footer .social-media a {
    background: none;
    border-radius: 0;
    font-size: 22px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    text-align: left;
    margin-right: 12px;
}

#footer .social-media a:hover i {
    color: #55a79a;
}

#footer .social-media a i {
    color: #999;
    font-size: 16px;
    text-align: left;
}

// NEW CALL TO ACTION FOOTER DESIGN
#call-to-action-footer {
    background: $base2-aqua;
    &> .call-to-action {
        display: flex;
        justify-content: center;

        @include breakpoint(to-medium) {
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 0;
        }
    }
    .container {
        padding: 20px !important;
        margin-bottom: 0 !important;
        background: none !important;
        @include breakpoint(from-medium) {
            padding: 26px 20px !important;
        }
        h2 {
            color: $white;
            font-size: clamp(26px, calc(1rem + 1vw), 30px);
            margin-bottom: 8px;
        }
        h3 {
            color: rgba($white, 0.7);
            line-height: 1.3;
        }
        h4 {
            color: rgba($white, 0.7);
        }
        p {
            color: rgba($white, .85);
        }
        ul {
            list-style: disc;
            padding-left: 20px;
            margin: 10px 0;
            @include breakpoint(to-medium) {
                margin-bottom: 24px;
            }
            li {
                color: rgba($white, .85);
                margin-bottom: 4px;
            }
        }
        hr.short {
            border-bottom: none;
            width: 46%;
            border-top-color: rgba($white, 0.7);
        }
        .form-left-column {
            @include breakpoint(from-medium) {
                width: 34%;
                margin-right: 32px;
            }
        }
        .form-right-column {
            padding-top: 12px;
            @include breakpoint(from-medium) {
                width: 50%;
            }
            form {
                margin-bottom: 0;
                position: relative;
                padding-bottom: 24px;
                label {
                    display: none;
                }
                input {
                    border: none;
                    border-radius: 5px;
                    background: darken($base2-aqua, 6%);
                    width: 100%;
                    color: rgba($white, .8);
                    &::placeholder {
                        color: rgba($white, 0.6);
                    }
                    @include breakpoint(from-medium) {
                        width: 49%;
                        float: left;
                    }
                    &.company {
                        @include breakpoint(from-medium) {
                            float: right;
                        }
                    }
                    &.email {
                        @include breakpoint(from-medium) {
                            float: right;
                        }
                    }
                }
                textarea {
                    border: none;
                    border-radius: 5px;
                    background: darken($base2-aqua, 6%);
                    width: 100%;
                    color: rgba($white, .8);
                    padding: 12px;
                    &::placeholder {
                        color: rgba($white, 0.6);
                        font-family: 'HelveticaNeue', 'Arial', sans-serif;
                    }
                }
                a.btn-primary {
                    margin: 0;
                    padding: 7px 22px 8px;
                    font-size: 11px;
                    font-weight: 600;
                    height: 33px;
                    background: $white;
                    color: $base2-aqua;
                    @extend %transition;
                    display: inline-block;
                    float: right;
                    width: auto;
                    border-radius: 5px;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        background: rgba($white, 0.8);
                        cursor: pointer;
                    }
                    &.fade,
                    &.fade-cta {
                        background: rgba($white, 0.3);
                    }
                }
                i.fa-spin {
                    float: right;
                }
            }
        }
        .alert {
            background: none;
            border: none;
            box-shadow: none;
            margin: 0;
            .alert-content {
                padding: 5px 0 !important;
                background: none;
                p {
                    color: $white !important;
                }
            }
        }
    }
}