.video-embed-container {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&.box-shadow {
			box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

		}

		.video-embed {
				display: inline-block;
				width: 100%;
				height: auto;
		}

		.video-info {
			display: flex;
			flex-direction: column;
			flex: 1;
				display: inline-block;
				width: 100%;
				height: auto;
				color: $white;
				padding: 12px 30px;
				margin-top: -5px;

				&.two-column {
					padding: 8px 16px;
				}

				h4 {
					margin-bottom: 18px !important;
					margin-top: 10px !important;
					color: $dark-grey;
					font-size: 17px;
				}
				p {
					margin-bottom: 10px;
					color: $dark-grey;
				}
				span.right {
					float: right;
					color: $dark-grey;
					font-size: 12px;
					text-align: right;
				}
		}
}

//video case studies sidebar
#content .container .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 20px; 

	a.video-link {
		object-fit: cover;
		aspect-ratio: 4/3;

		img {
			object-fit: cover;
			aspect-ratio: 4/3;
		}
	}

	.content {
		color: $white;
		background-color: $base2-aqua !important;
		padding: 20px !important;

		h5 {
			color: white;
			font-size: 20px;
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		}
		p {
			color: $white;

			&.link-cs {
				display: flex;
				align-items: center;
				gap: 12px;
				flex-direction: row;
				@extend %transition;
				text-transform: uppercase;

				&:hover {
					gap: 18px;
				}
			}
		}
	}
}

