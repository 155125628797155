// Header Section
#header {
	text-align: left;
	width: 100%;
	padding: 20px 0 10px;
	background-color: #FFF;
	border-bottom: 1px solid $light-grey;
	position: relative;
	z-index: 9999;
	@include breakpoint(to-small) {
		padding: 12px 0 0;
	}

	.container {
		height: 90px;
		padding: 0 20px;
		position: relative;

		@include breakpoint(from-medium) {
			padding: 0 30px;
		}

		// Logo
		a.logo {
			width: 100px;
			height: 90px;
			float: left;

			img {
				width: 100%;
			}
		}
		//Tagline
		.tag {
			display: none;
			width: 240px;
			margin-left: 30px;
			color: $base2-aqua;
			float: left;
			font-size: 18px;
			line-height: 80px;

			@include breakpoint(from-small) {
				display: block;
			}
		}
	}
	.contact-header {
		display: block;
		background-color: $white;
		text-align: center;
		margin-bottom: 8px;

		@include breakpoint(from-medium) {
			display: none;
		}
		a.phone-header {
			color: $base2-heading;
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			align-items: center;
			gap: 6px;
			&:after {
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\f095";
				font-size: 14px;
				display: block;
			}
		}
	}
}
