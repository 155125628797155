body.page {
    #content {
        iframe.hs-form-iframe {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}

.hbspt-form {
    width: 100% !important;
    max-width: 100% !important;
    input,
    textarea {
        background: none !important;
        font-family: 'HelveticaNeue', 'Arial', sans-serif;
        &.hs-input:not([type=file]) {
            background: none !important;
        }
        &::placeholder {
            color: $white !important;
        }
        &.error {
            border-color: red;
        }
    }
    input.hs-button {
        text-transform: uppercase !important;
    }
    .hs-error-msg {
        color: red;
        font-size: 13px;
    }
    .hs_error_rollup {
        label {
            color: red;
        }
    }
}

.contact-page {
    .hbspt-form {
        fieldset {
            width: 100%;
            max-width: 100%;
        }
        input[type=text],
        input[type=tel],
        input[type=email] {
            width: 100% !important;
        }
        textarea {
            width: 100% !important;
        }
        select {
            width: 100%;
        }
    }
}

.hs-richtext {
    font-size: 10px !important;
    line-height: 115%;
    p {
        margin-bottom: 8px !important;
        line-height: 125% !important;
    }
}

ul.inputs-list {
    list-style: none !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    font-size: 10px !important;
    li {
        list-style: none !important;
        font-size: 10px !important;
        margin-bottom: 8px !important;
    }
    span {
        font-size: 10px !important;
    }
}

.hs_submit {
    margin-top: 10px;
    input[type="submit"] {
        display: inline-block !important;
        max-width: 250px !important;
        margin: 10px 0 !important;
        padding: 8px 22px 8px 22px !important;
        height: 36px !important;
        font-size: 90% !important;
        color: #fff !important;
        text-align: center !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
        overflow: hidden !important;
        background: #00adbb !important;
        border-radius: 5px !important;
        position: relative !important;
        z-index: 5 !important;
        border: none !important;
        @extend %transition;
        &:hover {
            background-color: #007388 !important;
            color: #fff !important;
            @extend %transition;
            cursor: pointer !important;
        }
    }
}

#call-to-action-footer {
    .hbspt-form {
        form {
            fieldset {
                max-width: 100%;
            }
            @include breakpoint(from-medium) {
                //display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 10px;
                .hs_firstname {
                    grid-area: 1 / 1 / 2 / 2;
                }
                .hs_lastname {
                    grid-area: 1 / 2 / 2 / 3;
                }
                .hs_company {
                    grid-area: 2 / 1 / 3 / 2;
                }
                .hs_phone {
                    grid-area: 2 / 2 / 3 / 3;
                }
                .hs_email {
                    grid-area: 3 / 1 / 4 / 2;
                }
                .hs_country {
                    grid-area: 3 / 2 / 4 / 3;
                }
                .hs_message {
                    grid-area: 4 / 1 / 5 / 3;
                }
                .legal-consent-container {
                    grid-area: 5 / 1 / 6 / 3;
                }
                .hs_submit {
                    grid-area: 6 / 1 / 7 / 3;
                }
            }
        }
        input,
        textarea {
            background: #00919c !important;
            width: 100% !important;
            font-family: 'HelveticaNeue', 'Arial', sans-serif;
            &::placeholder {
                color: rgba(255, 255, 255, 0.6) !important;
                display: block;
                float: none;
            }
            &.error {
                border: 1px solid $white !important;
            }
        }
        select {
            width: 100%;
            background: #00919c !important;
            border: none;
            color: rgba($white, 0.6);
            -webkit-appearance: none;
            @include breakpoint(from-medium) {
                float: right !important;
                position: relative;
                top: 4px;
            }
        }
        input[type="submit"] {
            margin: 0 !important;
            padding: 7px 22px 8px !important;
            font-size: 11px !important;
            font-weight: 600 !important;
            height: 33px !important;
            background: $white !important;
            color: $base2-aqua !important;
            display: inline-block !important;
            float: right !important;
            width: auto !important;
            border-radius: 5px !important;
            @extend %transition;
            &:hover {
                background: rgba($white, 0.8) !important;
                cursor: pointer;
                @extend %transition;
            }
        }
        .hs-richtext {
            color: $white !important;
            a {
                color: $white !important;
                text-decoration: underline !important;
            }
        }
        label.hs-error-msg {
            display: block !important;
        }
        ul.hs-error-msgs {
            padding-left: 4px;
            li {
                label {
                    color: $white;
                    font-size: 10px;
                }
            }
        }
    }
}

.chrome {
    #call-to-action-footer {
        .hbspt-form {
            select {
                height: 32px;
                padding-top: 1px;
                padding-bottom: 7px;
                padding-right: 5px;
                @include breakpoint(from-medium) {
                    top: 0 !important;
                }
            }
        }
    }
}

.safari {
    #call-to-action-footer {
        .hbspt-form {
            select {
                height: 32px;
                padding-top: 0px;
                padding-bottom: 0px;
                @include breakpoint(from-medium) {
                    top: 2px !important;
                }
            }
        }
    }
}

.chrome {
    .hbspt-form {
        select {
            height: 32px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-right: 5px;
            @include breakpoint(from-medium) {
                top: 0 !important;
            }
        }
    }
}

// Tech osts side Bar
// .sidebar-accordion {
//     .content {
//         .hbspt-form {
//             label {
//                 display: none;
//             }
//             input {
//                 border: none;
//                 border-radius: 5px;
//                 background: darken($base2-aqua, 6%) !important;
//                 width: 100%;
//                 color: rgba($white, .8) !important;
//                 &::placeholder {
//                     color: rgba($white, 0.6) !important;
//                 }
//                 &.hs-button {
//                     margin: 0;
//                     padding: 10px 20px;
//                     background: $white;
//                     color: $base2-aqua;
//                     @extend %transition;
//                     display: inline-block;
//                     float: right;
//                     width: auto;
//                     &:hover {
//                         background: rgba($white, 0.8);
//                         cursor: pointer;
//                     }
//                     &.fade {
//                         background: rgba($white, 0.3);
//                     }
//                 }
//             }
//             select {
//                 width: 100%;
//                 background: #00919c !important;
//                 border: none;
//                 color: rgba($white, 0.6);
//                 -webkit-appearance: none;
//                 @include breakpoint(from-medium) {
//                     float: right !important;
//                     position: relative;
//                     top: 4px;
//                 }
//             }
//             .actions {
//                 display: flex;
//                 justify-content: flex-end;
//             }
//             input[type="submit"] {
//                 margin: 0 !important;
//                 padding: 7px 22px 8px !important;
//                 font-size: 11px !important;
//                 font-weight: 600 !important;
//                 height: 33px !important;
//                 background: $white !important;
//                 color: $base2-aqua !important;
//                 display: inline-block !important;
//                 float: none !important;
//                 width: auto !important;
//                 border-radius: 5px !important;
//                 @extend %transition;
//                 &:hover {
//                     background: rgba($white, 0.8) !important;
//                     cursor: pointer;
//                     @extend %transition;
//                 }
//             }
//             .privacy-policy-link {
//                 font-weight: 600;
//                 color: $white !important;
//                 font-size: 11px !important;
//                 line-height: 38px !important;
//             }
//             .hs-richtext {
//                 opacity: 0.85;
//                 a {
//                     color: $white !important;
//                     text-decoration: underline;
//                 }
//             }
//         }
//     }
// }

// .chrome {
//     .sidebar-accordion {
//         .hbspt-form {
//             select {
//                 height: 32px;
//                 padding-top: 1px;
//                 padding-bottom: 7px;
//                 padding-right: 5px;
//                 @include breakpoint(from-medium) {
//                     top: 0 !important;
//                 }
//             }
//         }
//     }
// }

// .safari {
//     .sidebar-accordion {
//         .hbspt-form {
//             select {
//                 height: 32px;
//                 padding-top: 0px;
//                 padding-bottom: 0px;
//                 @include breakpoint(from-medium) {
//                     top: 2px !important;
//                 }
//             }
//         }
//     }
// }

body.page {
    .hs_recaptcha {
        display: none !important;
    }
}