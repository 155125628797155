// Community Landing
#content {

	// Community Navigation
	ul.sub-nav {
		display: none;

		li {
			@extend %transition;
		}
		@include breakpoint(from-medium) {
			display: block;

			&.container {
				margin-top: 0;
				padding: 0;
				background-color: #b3b3b3;
			}

			li {
				width: 25%;
				text-align: center;
				border-right: 1px solid #dedede;
				float: left;
				font-weight: 400;
				text-transform: uppercase;

				a {
					color: $white;
					font-size: 13px;
					display: block;
					padding: 16px 0;
				}
				&:last-child {
					border-right: none;
				}
				&:hover {
					background-color: #c6c6c6;
					cursor: pointer;
				}
			}
		}
	}

	.container {
		ul.columns {
			margin-left: 0;
			li {
				overflow: hidden;
				height: auto;
				position: relative;
				padding: 0;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				.partner-logo{
					flex: 1;
					display: flex;
					align-items: center;

					.img-outer {
						display: flex;
						flex: 1;
						align-items: center;
						justify-content: center;
						height: 100px;
					}
					img {
						width: auto;
						max-height: 100px;
						display: flex;
						max-width: 200px;
					}
				}
				p {
					display: flex;
					flex-grow: 1;
					margin-bottom: 22px;
				}

				@include breakpoint(from-medium) {
					padding-bottom: 0;
				}

				a {
					position: relative;
				}

				&.video {
					height: auto;

					.column-thumb {
						height: auto;
						position: relative;

						&:hover {
							opacity: 1;
						}
					}
				}

				&.visual {
					position: relative;
					.img-equal {
						aspect-ratio: 3/2;
					}
					img {
						width: 100%;
						overflow: hidden;
						vertical-align: top;
						height: auto;
						@extend %transition;
						object-fit: cover;
						aspect-ratio: 3/2;

						@include breakpoint(from-medium) {
							margin-bottom: 0;
							box-shadow: none;
							width: 100%;
							height: auto;
						}

						&:hover {
							opacity: 0.8;
							cursor: pointer;
						}

					}

					.post-excerpt {
						padding: 0 10px;

						h4 {
							margin-top: 20px;
						}
					}

					// image
					.column-thumb {
						margin: 0;
						height: auto;
						overflow: hidden;
						line-height: 100%;
						position: relative;
						z-index: 14;
					}

					// teaser text
					.column-link {
						position: relative;
					}
					.teaser {
						color: #454545;
						h4 {
							color: $base2-aqua;
							margin-top: 20px;
						}
						@include breakpoint(from-medium) {
							padding: 30px;
							position: absolute;
							top: 120px;
							opacity: 1;
							background-color: rgba(17,98,105,0.9);
							//background-color: rgba(42, 45, 46, 0.95);
							height: 100%;
							color: $white;
							font-weight: 400;
							z-index: 15;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;

							h4 {
								color: $white;
								font-weight: bold;
								border-bottom: 1px solid rgba(22,120,141,1);
								padding-bottom: 8px;
							}
						}
						@include breakpoint(from-medium) {
							opacity: 0;
							-webkit-transition: all 1s ease 0s;
							-moz-transition: all 1s ease 0s;
							-ms-transition: all 1s ease 0s;
							-o-transition: all 1s ease 0s;
							transition: all 1s ease 0s;
							-webkit-transform-style: preserve-3d; // Stop the flicker
						}
						@include tablet-portrait {
							p {
								display: none;
							}
						}
					}
					&.video:hover {
						cursor: pointer;

						.teaser {
							@include breakpoint(from-medium) {
								opacity: 1;
								top: 0;
								bottom: 0;
								right: 0;
								left: 0;
							}
						}
					}
				}
			}
			// Visual Story
			&.visual-story {
				margin-bottom: 0;

				li {
					&.visual {
						margin: 0 !important;
						border: none !important;
					}
				}
			}
		}
		// Reverse Block
		&.base2-aqua,
		&.base2-green,
		&.base2-lime,
		&.base2-blue,
		&.base2-turquoise {
			color: $white!important;

			ul.columns {

				li.visual {
					border-color: $base2-aqua;
				}
			}
		}
		&.base2-green {
			ul.columns {
				li.visual {
					border-color: $base2-green;
				}
			}
		}
		&.base2-lime {
			ul.columns {
				li.visual {
					border-color: $base2-lime;
				}
			}
		}
		&.base2-blue {
			ul.columns {
				li.visual {
					border-color: $base2-blue;
				}
			}
		}
		&.base2-turquoise {
			ul.columns {
				li.visual {
					border-color: $base2-turquoise;
				}
			}
		}
	}
}

// PDF download bar
#content {
	.pdf-wrapper {
		.container {
			background-color: $base2-blue;
			padding: 20px;
			margin-top: -30px;
			@extend %transition;

			&:hover {
				background-color: $base2-aqua;
			}

			@include breakpoint(from-medium) {
				padding: 30px 50px;
			}
			a {
				color: $white;
				font-weight: 600;
				padding-left: 36px;
				display: block;
				@extend %transition;
				text-transform: uppercase;

				&:before {
					position:absolute;
					top: -5px;
					@include font-awesome("\f1c1");
					z-index: 20;
					left: 0;
					display: block;
					font-size: 200%;
				}
			}
		}
	}
}

//Presented

.presentation {
	display: flex;
	flex-direction: column;
	a {
		display: flex;
	}
	img {
		width: 100%;
		height: auto;
	}
	.post-excerpt {
		padding: 8px;
	}
	.tags {
		display: flex;
		flex-direction: row;
		gap: 2px;
	}
}

// Events
.event-content {
	@include breakpoint (from-medium) {
		@include grid(60);
		float: left;
	}
}
.event-details {
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid $light-grey;

	@include breakpoint (from-medium) {
		@include grid(33);
		float: right;
		margin-left: 30px;
		margin-bottom: 30px;
		border: 1px solid $light-grey;
		background-color: #f5f5f5;
		margin-top: 0;
		border-top: none;
		padding-top: 0;
	}
	.event {
		@include breakpoint(from-medium) {
			padding: 30px;
			font-size: 15px;
			line-height: 145%;
		}

		img {
			margin: 20px auto 30px;
			@extend %dropshadow;
			max-width: 80%;
			margin-left: 10%;
		}

		.event-heading {
			margin-top: 20px;
		}

		.btn-primary {
			padding: 8px 10px;
		}
	}
}
