// Process Arrows
#content {
	.container.inner {
		.process-arrows {
			padding: 30px 0 68px;
			clear: both;
			text-align: center;

				h2 {
					text-align: center;
					color: $base2-blue;
				}

				ul {
					margin: 0;
					padding: 20px 0;

					@include breakpoint(from-medium) {
						&.two-sections li {
							width: 48%;
						}
						&.three-sections li {
							width: 31%;
						}
						&.four-sections li {
							width: 23%;
						}
						&.five-sections li {
							width: 18%;
						}
					}
				}

				li {
					float: left;
					padding: 20px 0;
					background: $base2-aqua;
					color: #fff;
					position: relative;
					font-weight: bold;
					margin: 0 0 62px;
					list-style: none;
					font-size: 130%;
					height: auto;
					width: 100%;

					@include breakpoint(from-medium) {
						margin: 0 1% 0 0;
					}

					p {
						padding: 0;
						width: 100%;
					}

					i {
						display: block;
						width: 100%;
						font-size: 50px;
						margin-bottom: 12px;
					}
					&:before {
						border-color: $white;
					}
					&:after {
						border-color: $base2-aqua;
					}

				// Mobile
				@include breakpoint(to-medium) {
					i {
						margin-top: 20px;
					}
					&:before {
						content: '';
						border: none;
					}
					&:after {
						content: '';
						border-top-width: 68px;
						border-top-style: solid;
						border-left: 100px solid transparent;
						border-right: 100px solid transparent;
						position: absolute;
						top: 100%;
						left: 50%;
						z-index: 20;
						margin-left: -100px;
					}

					&:first-child {
						&:before {
							border: none;
						}
					}
					// Changing background colours
					&:nth-child(2) {
						background: $base2-turquoise;
						padding-top: 70px;
						z-index: 4;
						margin-top: -62px;

						&:after {
							border-color: $base2-turquoise;
							content: '';
							border-top-width: 68px;
							border-top-style: solid;
							border-left: 100px solid transparent;
							border-right: 100px solid transparent;
							position: absolute;
							top: 100%;
							left: 50%;
							z-index: 20;
							margin-left: -100px;
						}
					}
					&:nth-child(3) {
						background: $base2-green;
						padding-top: 70px;
						z-index: 3;
						margin-top: -62px;

						&:after {
							border-color: $base2-green;
							content: '';
							border-top-width: 68px;
							border-top-style: solid;
							border-left: 100px solid transparent;
							border-right: 100px solid transparent;
							position: absolute;
							top: 100%;
							left: 50%;
							z-index: 20;
							margin-left: -100px;
						}
					}
					&:nth-child(4) {
						background: $base2-lime;
						padding-top: 70px;
						z-index: 2;
						margin-top: -62px;
						padding-bottom: 68px;

						&:after {
							border-color: $base2-lime;
							content: '';
							border-top-width: 68px;
							border-top-style: solid;
							border-left: 100px solid transparent;
							border-right: 100px solid transparent;
							position: absolute;
							top: 100%;
							left: 50%;
							z-index: 20;
							margin-left: -100px;
							border: none;
						}
					}
				}
				// Large Screen
				@include breakpoint(from-medium) {
					height: 136px;

					p, i {
						margin-left: 30px;
					}

					&:before {
					  content: '';
					  border-left-width: 68px;
					  border-left-style: solid;
					  border-top: 68px solid transparent;
					  border-bottom: 68px solid transparent;
					  position: absolute;
					  top: 0;
					  left: 0;
					}
					&:after {
					  content: '';
					  border-left-width: 68px;
					  border-left-style: solid;
					  border-top: 68px solid transparent;
					  border-bottom: 68px solid transparent;
					  position: absolute;
					  top: 0;
					  left: 100%;
					  z-index: 20;
					}

					&:first-child {
						&:before {
							border: none;
						}
						i, p {
							margin-left: 15px;
						}
					}
					// Changing background colours
					&:nth-child(2) {
						background: $base2-turquoise;

						&:after {
							border-color: $base2-turquoise;
							content: '';
							border-left-width: 68px;
							border-left-style: solid;
							border-top: 68px solid transparent;
							border-bottom: 68px solid transparent;
							position: absolute;
							top: 0;
							left: 100%;
							z-index: 20;
						}
					}
					&:nth-child(3) {
						background: $base2-green;

						&:after {
							border-color: $base2-green;
							content: '';
							border-left-width: 68px;
							border-left-style: solid;
							border-top: 68px solid transparent;
							border-bottom: 68px solid transparent;
							position: absolute;
							top: 0;
							left: 100%;
							z-index: 20;
						}
					}
					&:nth-child(4) {
						background: $base2-lime; 

						&:after {
							border-color: $base2-lime;
							content: '';
							border-left-width: 68px;
							border-left-style: solid;
							border-top: 68px solid transparent;
							border-bottom: 68px solid transparent;
							position: absolute;
							top: 0;
							left: 100%;
							z-index: 20;
						}
					}
				}
			}
		}
	}
}

// DevOps diagram
#content {
	.container.inner {
		.what-you-get {
			h2 {
				text-align: center;
				color: $base2-blue;
			}
			ul {
				margin: 0;
				padding: 30px 0 68px;

				li {
					list-style: none;
					float: left;
					padding: 20px 0;
					background: $base2-aqua;
					color: #fff;
					position: relative;
					font-weight: bold;
					margin: 0 0 62px;
					list-style: none;
					font-size: 130%;
					height: 268px;
					width: 100%;

					@include breakpoint(from-medium) {
						margin: 0 1% 0 0;
					}

					p {
						padding: 0;
						width: 100%;
					}

					i {
						display: block;
						width: 100%;
						font-size: 50px;
						margin-bottom: 12px;
					}
					&:before {
						border-color: $white;
					}
					&:after {
						border-color: $base2-aqua;
					}

					@include breakpoint(to-medium) {
						&:before {
							content: '';
							border: none;
						}
						&:after {
							content: '';
							border-top-width: 68px;
							border-top-style: solid;
							border-left: 100px solid transparent;
							border-right: 100px solid transparent;
							position: absolute;
							top: 100%;
							left: 50%;
							z-index: 20;
							margin-left: -100px;
						}

						&:first-child {
							&:before {
								border: none;
							}
						}
						&:nth-child(2) {
						background: $base2-turquoise;
						text-align: center;
						height: auto;
						padding-top: 70px;
						z-index: 2;
						margin-top: -62px;


							&:after {
								border-color: $base2-turquoise;
								content: '';
								border: none;
							}
						
						.icon-column {
							margin-bottom: 20px;
						}

						}
					}
					// Large Screen
					@include breakpoint(from-medium) {
						height: 260px;

						p, i {
							margin-left: 30px;
						}

						&:before {
						  content: '';
						  border-left-width: 68px;
						  border-left-style: solid;
						  border-top: 130px solid transparent;
						  border-bottom: 130px solid transparent;
						  position: absolute;
						  top: 0;
						  left: 0;
						}
						&:after {
						  content: '';
						  border-left-width: 68px;
						  border-left-style: solid;
						  border-top: 130px solid transparent;
						  border-bottom: 130px solid transparent;
						  position: absolute;
						  top: 0;
						  left: 100%;
						  z-index: 20;
						}

						&:first-child {
							width: 24%;
							&:before {
								border: none;
							}
							i, p {
								margin-left: 15px;
							}
						}
						// Changing background colours
						&:nth-child(2) {
							background: $base2-turquoise;
							width: 74%;

							&:after {
								border-color: $base2-turquoise;
								content: '';
								border: none;
								height: 240px;
							}
						}

						.icon-column {
							float: right;
							width: 23%;
							text-align: center;
							padding-top: 50px;

							i{
								margin-left: 0;
								font-size: 80px;
							}
							p {
								margin-left: 0;
							}

							&:nth-child(1) {

							}
						}
					}

					&.column1 {
						i {
							margin-right: 6px;
							clear: left;
							width: 66px;
							height: 36px;
							float: left;
							margin-left: 0;
						}
						p {
							line-height: 36px;
							position: relative;
							top: 3px;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}