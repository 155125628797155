.flip-card {
  perspective: 1000px;

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }

    .flip-card-front {
      z-index: 2;
    }

    .flip-card-back {
      transform: rotateY(180deg);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      z-index: 1;
      text-align: center;

      a {
        img {
          width: 50%;
          margin-bottom: 18px;
          text-shadow: none;
        }

        p {
          padding-top: 14px;
          border-top: 2px solid rgba(255, 255, 255, 0.5);
          color: #fff;
        }
      }
    }
  }

  &:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  //  Disable flip on small screens and show both sides stacked
  @media (max-width: 768px) {
    .flip-card-inner {
      transform: none !important;
      transform-style: flat !important;

      .flip-card-front,
      .flip-card-back {
        position: relative;
        height: 40%;
        transform: none;
        backface-visibility: visible;
        z-index: auto;
      }
      
      .flip-card-front {
        margin-bottom: 1rem;
      }
    }

    &:hover .flip-card-inner {
      transform: none;
    }
  }
}
