// Customers Landing page
#content {
    .container {
        .grid-4-logos,
        .grid-5-logos,
        .grid-6-logos,
        .grid-3-logos {
            padding-left: 0;
            display: grid;
            gap: 0 18px;
            margin-left: 0 !important;
            align-items: center;
            @include breakpoint(from-small) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include breakpoint(from-medium) {
                grid-template-columns: repeat(3, 1fr);
            }
            @include breakpoint(from-large) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        .grid-5-logos {
            @include breakpoint (to-small) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include breakpoint(from-medium) {
                grid-template-columns: repeat(4, 1fr);
            }
            @include breakpoint(from-large) {
                grid-template-columns: repeat(5, 1fr);
            }
        }
        .grid-3-logos {
            @include breakpoint(from-medium) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
        .grid-6-logos {
            @include breakpoint (to-medium) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include breakpoint(from-medium) {
                grid-template-columns: repeat(4, 1fr);
            }
            @include breakpoint(from-large) {
                grid-template-columns: repeat(6, 1fr);
            }
            
        }
        // Customer Logo Grid
        .customer-logos {
            .mix {
                display: none;
            }
            li {
                display: block;
                text-align: center;
                padding: 12px !important;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    display: flex;
                    @extend %transition;
                    white-space: nowrap;
                    position: relative;
                    align-items: center;
                    padding: 0;

                    .tooltip {
                        display: block;
                        position: absolute;
                        width: 100%;
                        padding-left: 8px;
                        padding-right: 8px;
                        height: 30px;
                        line-height: 30px;
                        background: $base2-blue;
                        color: $white;
                        left: 0;
                        top: 10px;
                        -webkit-transition: opacity 0.3s ease-in;
                        transition: opacity 0.3s ease-in;
                        opacity: 0;

                        &:after {
                            display: block;
                            position: absolute;
                            bottom: -6px;
                            left: 48%;
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 7px 7px 0 7px;
                            border-color: $base2-blue transparent transparent transparent;
                        }
                    }

                    &:hover {
                        opacity: 0.7;

                        .tooltip {
                            opacity: 1;
                            z-index: 9999;
                        }

                        img {
                            @extend %black-white;
                            opacity: 0.6;
                        }
                    }
                }

                .helper {
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }

                img {
                    margin: 0 auto;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}

// Customers Page
.customer-page {
    @extend %clearfix;
}

.customer-row {
    padding: 16px 0;
    margin-top: 16px;
    border-top: 1px solid $light-grey;
    clear: both;

    @include breakpoint(from-medium) {
        padding: 26px 0;
        margin-top: 20px;
    }
}

.customer-row-links {
    clear: both;
}

.customer-page-testimonial {
    padding: 16px 0;
    clear: both;

    @include breakpoint(from-medium) {
        padding: 26px 0;
    }
}

//Case Study Page
#content {
    .content-case-study {

        h2 {
            font-size: clamp(22px, calc(1rem + 1vw), 26px);
        }

        h2 span.blue {
            color: $case-icon-blue;
        }
        .case-study-header {
            padding-bottom: 22px;
            //background: linear-gradient(90deg, rgba(47,170,231,1) 0%, rgba(26,125,139,1) 70%), rgba(26,125,139,1) 100%;
        }
        .section-title {
            &:after {
                border-color: transparent;
                padding-top: 4px;
                background: linear-gradient(90deg, rgba(22,115,121,1) 5%, rgba(48,174,239,1) 35%, rgba(111,204,214,1) 63%, rgba(213,226,116,1) 90%);
                margin-top: 18px;
            }
        }
        .case-study-image {
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            padding-top: 88px;
            padding-bottom: 18px;
            min-height: 450px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 108px;
            position: relative;

            @include breakpoint(to-small) {
                margin-bottom: 128px;
            }

            blockquote {
                flex-direction: column;
                justify-content: flex-start;
                background-color: rgba($white, 1);
                padding: 36px;
                margin: 0;
                padding-left:68px;
                position: absolute;
                bottom: -44px;
                border-radius: 12px;
                box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
                right: 24px;

                &:after {
                    display: none;
                }

                @include breakpoint(to-medium) {
                    right: 3%;
                    width: 94%;
                }

                @include breakpoint(from-xlarge) {
                    right: -44px;
                }
                @include breakpoint(to-small) {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 16px;
                    bottom: -88px;
                }

                &:before {
                    content: "\f10d";
                    display: block;
                    font-family: FontAwesome;
                    font-size: 36px;
                    position: absolute;
                    left: 16px;
                    top: 26px;
                    color: $base2-heading;

                    @include breakpoint(to-small) {
                        font-size: 16px;
                        top: 2px;
                    }
                }

                @include breakpoint(from-medium) {
                    width: 80%;
                }

                p {
                    background: none;
                    margin: 0;
                    padding: 0;
                    font-size: 17px;
                    line-height: 1.35;
                    margin-bottom: 14px;
                    font-weight: bold;
                    letter-spacing: 0.015rem;

                    &:after {
                        display: none;
                    }

                    @include breakpoint(to-medium) {
                        font-size: 14px;
                    }
                }
                span {
                    color: $base2-blue;
                    font-weight: bold;
                    text-align: left;
                }
                .case-study-logo {
                    margin-left: 0;
                    margin-top: 12px;
                    margin-bottom: 0;
                    max-width: 180px;
                    max-height: 140px;
                    display: block;
                    height: auto;
                    margin-right: auto;

                    @include breakpoint(to-small) {
                        max-height: 90px;
                        max-width: 110px;
                    }

                    &.tall-logo {
                        max-height: 92px;
                    }
                    &.square-logo {
                        max-height: 62px;
                    }
                }
                
            }
        }
        .case-study-results {
            background: $white;
            //color: $white;
            padding: 36px;
            padding-right: 46px;
            margin-top: 88px;

            @include breakpoint(to-small) {
                padding-left: 16px !important;
                padding-right: 16px !important;
                padding-top: 22px !important;
                padding-bottom: 22px !important;
            }

            h2, li {
                //color: $white;
            }
            h2 {
                margin-bottom: 18px;
                text-align: center;
                margin-top: 0;
            }
            .casestudy-columns {
                display: flex;
                flex-direction: column-reverse;
                gap: 24px;
                >div {
                    width: 100%;
                }
                ul {
                   margin-bottom: 0; 
                   padding-bottom: 0;
                   list-style: none;
                }
            }
            .casestudy-col-one {
                padding: 24px;

                @include breakpoint(to-mobile) {
                    padding-left: 0;
                    padding-right: 0;
                }

                ul {
                    list-style: none;
                    margin-left: 0;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    flex-direction: column;
                    gap: 22px;

                    @include breakpoint(to-mobile) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    li {
                        list-style: none;
                        display: flex;
                        align-items: flex-start;
                        gap: 16px;
                        margin: 0;
                        padding: 0;
                        font-size: 16px;

                        &:before {
                            content: "\f00c";
                            display: block;
                            font-family: FontAwesome;
                            font-size: 22px;
                            color: $base2-blue;
                            position: relative;
                            top: 2px;
                        }
                    }
                }
            }
            .casestudy-col-two {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 0 34px;
                margin-top: 24px;
                

                &.no-result-icon-3 {
                    grid-template-columns: 1fr 1fr;
                }
                &.only-one-result {
                    
                    .result-1 {
                        order: 2;
                    }
                }

                @include breakpoint(to-mobile) {
                    grid-template-columns: 1fr;
                    gap: 12px;
                }

                &:before {
                    display: none;
                }
            }
            .result-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 14px;
                flex-grow: 1;
                background-color: darken($base2-blue, 1);
                padding: 12px 24px 22px;
                border-radius: 8px;
                color: $white;
                box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

                &.result-2 {
                    background-color: darken($base2-blue, 5);
                }
                &.result-3 {
                    background-color: darken($base2-blue, 10);
                }

                @include breakpoint(to-mobile) {
                    flex-direction: row;
                }
                @include breakpoint(to-small) {
                    flex-direction: column;
                }



                i {
                    font-size: 96px;
                    color: lighten($case-icon-blue, 5);
                    width: auto;
                    height: 90px;
                    margin: 0;
                    padding: 0;
                    &:before {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
            .result-text {
                display: flex;
                justify-content: center;
                flex-direction: column;
                flex: 1;
                flex-grow: 1;
                gap: 6px;

                .case-summary-title {
                    font-weight: bold;
                    font-size: 24px;
                    color: #d5e274;
                    text-align: center;
                    margin-bottom: 12px;
                }
                .case-summary-text {
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
        .case-study-content{
            padding: 36px !important;

            @include breakpoint(to-small) {
                padding-left: 16px !important;
                padding-right: 16px !important;
                padding-top: 22px !important;
                padding-bottom: 22px !important;
            }

            h2 {
                margin-top: 0;
                flex-grow: 1;
            }
            &.intro {
                margin-bottom: 0;
            }
        }
    }
    img.page-image {
        max-width: 100%;
        height: auto;
        padding: 24px;
        padding-bottom: 44px;
    }
    .sidebar-accordion.case-study-content{
        padding: 0 !important;
        margin-top: 40px;
        margin-bottom: 44px;
        a {
            display: flex !important;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            background: $white !important;
            border-radius: 3px;
            height: 72px !important;
            padding-left: 36px !important;
            padding-right: 36px;
            box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
            position: relative;
            z-index: 2;

            @include breakpoint(to-small) {
                padding-left: 16px !important;
                padding-right:  16px;
            }

            &:after {
                color: $dark-grey !important;
                top: 24px !important;
            }
        }
        h2 {
            margin: 0;
            padding: 0;
        }
        .accordion-content {
            background: $white;
            position: relative;
            z-index: 3;
            padding: 36px;
            box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;

            @include breakpoint(to-small) {
                padding-left: 16px !important;
                padding-right:  16px;
            }

            .case-study-aws-bullets {
                display:grid; 
                gap: 8px;
                @include breakpoint(from-small) {
                    grid-template-columns: repeat(2, 1fr); 
                }
                &.has-bullets {
                    grid-template-columns: repeat(1, 1fr);
                    list-style: disc;
                    list-style-type: disc;
                    list-style-position: outside;
                    padding-left: 14px;
                    gap: 12px;
                    li {
                        list-style: disc;
                    }
                }
            }
        }
        &.active {
            a {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
    .case-study-content.testimonial {
        width: 100%;
        background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        padding: 0 !important;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 0 !important;
        float: none;

    }
    .testimonial-quote {
        &.new-layout {
                background-color: #00adbb;
                padding: 36px;
                margin: 0;
                padding-left: 68px;
                border-radius: 12px;
                //box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
                position: relative;
                float: none;
                right: 24px;
                flex-direction: column;

                @include breakpoint(from-xlarge) {
                    right: -44px;
                }

                @include breakpoint(to-medium) {
                    right: auto;
                    
                }

                @include breakpoint(to-small) {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 16px;
                }

                &:before {
                    content: "\f10d";
                    display: block;
                    font-family: FontAwesome;
                    font-size: 36px;
                    position: absolute;
                    left: 16px;
                    top: 26px;
                    color: rgba($white, .5);

                    @include breakpoint(to-small) {
                        font-size: 16px;
                        top: 2px;
                    }
                }

                @include breakpoint(from-medium) {
                    width: 80%;
                }

                p {
                    background: none;
                    margin: 0;
                    padding: 0;
                    font-size: 17px;
                    line-height: 1.35;
                    margin-bottom: 14px;
                    font-weight: bold;
                    color: $white;
                    letter-spacing: 0.015rem;
                    &:after {
                        display: none;
                    }
                    @include breakpoint(to-small) {
                        font-size: 14px;
                    }
                }
                span {
                    color: $white;
                    font-weight: bold;
                    text-align: left;
                    margin-top: 12px;
                }                
            }
        }
    }
    .video-embed-container.casestudy-page {
        padding: 0!important;
        margin-bottom: 0;
        margin-top: 30px;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);


        .video-info {
            background: $white !important;
        }
    }
// Windows fix for old icon font
    .win #content {
        .content-case-study {
            .case-study-results {
                .result-icon {
                    i {
                        padding-top: 36px;
                        
                        &.fa-check {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }

    .case-study-card {
        strong {
            display: block;
            margin-top: 16px;
        }
    }