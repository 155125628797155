/*--------------------------------------------------------------
Minimal Grid
--------------------------------------------------------------*/

// Border Box all the things
*, *:before, *:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

// PX to EM Conversion
$base-em-context: 16;
@function em($pixels, $context: $base-em-context) {
  @return #{$pixels/$context}em
}

// Starter Breakpoints
$breakpoint-small:  em(440);
$breakpoint-medium: em(800);
$breakpoint-large:  em(1200);
$breakpoint-xlarge: em(1350);

$breakpoint-mobile: em(600);

// Navigation Breakpoint
$breakpoint-mobile-nav: em(1000);

// Grid Defaults
$minimal-grid-container-max-width:1224px;
$minimal-grid-gutter-width:20px;

// Content Width
$content_width:1224px;

// Clearfix
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Grid Block
%grid-block {
    padding-left:($minimal-grid-gutter-width/2);
    padding-right:($minimal-grid-gutter-width/2);
    display: inline-block;
    float: left;
}

// Grid Container
%grid-container {
    max-width: $minimal-grid-container-max-width;
    margin: 0 auto;
    @extend %clearfix;
    > section {
        @extend %grid-block;
    }
}

// Grid Container Collapse
%grid-container-collapse {
    max-width: $minimal-grid-container-max-width;
    margin: 0 auto;
    @extend %clearfix;
    > section {
        @extend %grid-block;
        padding:0;
    }
}

// Block Container
%block-container {
    max-width: $minimal-grid-container-max-width;
    padding:($minimal-grid-gutter-width/4);
    margin: 0 auto;
    @extend %clearfix;
    > li {
        @extend %grid-block;
        list-style: none;
        margin:0;
        padding:($minimal-grid-gutter-width/4);
    }
}

// Grid Mixin
@mixin grid($percentage) {
    @if $percentage == 10       { width:10%; }
    @else if $percentage == 15  { width:15%; }
    @else if $percentage == 16  { width:16.66%; }
    @else if $percentage == 20  { width:20%; }
    @else if $percentage == 25  { width:25%; }
    @else if $percentage == 30  { width:30%; }
    @else if $percentage == 33  { width:33.33%; }
    @else if $percentage == 35  { width:35%; }
    @else if $percentage == 40  { width:40%; }
    @else if $percentage == 45  { width:45%; }
    @else if $percentage == 50  { width:50%; }
    @else if $percentage == 55  { width:55%; }
    @else if $percentage == 60  { width:60%; }
    @else if $percentage == 66  { width:66.66%; }
    @else if $percentage == 70  { width:70%; }
    @else if $percentage == 75  { width:75%; }
    @else if $percentage == 80  { width:80%; }
    @else if $percentage == 85  { width:85%; }
    @else if $percentage == 90  { width:90%; }
    @else if $percentage == 95  { width:95%; }
    @else if $percentage == 100 { width:100%; }
    @else {width:$percentage*1%;}
}

// Breakpoint Mixin
@mixin breakpoint($point) {
    @if $point == to-small {
        @media only screen and (max-width: $breakpoint-small) { @content; }
    }
    @else if $point == from-small {
        @media only screen and  (min-width: $breakpoint-small) { @content; }
    }
    @else if $point == small-to-medium {
        @media only screen and  (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) { @content; }
    }
    @else if $point == to-medium {
        @media only screen and  (max-width: $breakpoint-medium) { @content; }
    }
    @else if $point == from-medium {
        @media only screen and  (min-width: $breakpoint-medium) { @content; }
    }
    @else if $point == medium-to-large {
        @media only screen and  (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) { @content; }
    }
    @else if $point == to-large {
        @media only screen and (max-width: $breakpoint-large) { @content; }
    }
    @else if $point == from-large {
        @media only screen and (min-width: $breakpoint-large) { @content; }
    }
    @else if $point == large-to-xlarge {
        @media only screen and  (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge) { @content; }
    }
    @else if $point == to-xlarge {
        @media only screen and (max-width: $breakpoint-xlarge) { @content; }
    }
    @else if $point == from-xlarge {
        @media only screen and (min-width: $breakpoint-xlarge) { @content; }
    }
    @else if $point == to-mobile {
        @media only screen and (max-width: $breakpoint-xlarge) { @content; }
    }
    @else if $point == from-mobile {
        @media only screen and (min-width: $breakpoint-xlarge) { @content; }
    }

    // Navigation Breakpoint
    @else if $point == mobile-nav {
        @media only screen and  (min-width: $breakpoint-mobile-nav) { @content; }
    }
}
