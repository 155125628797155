// Resets
// Placeholder Outline Fix
input:-webkit-autofill {
    background: rgba(120, 214, 208, 0.5)!important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

$placeholder: #b5b5b5;
::-webkit-input-placeholder {
    color: $placeholder;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder;
}

:-ms-input-placeholder {
    color: $placeholder;
}

textarea:focus,
input:focus,
input[type]:focus,
.uneditable-input:focus {
    box-shadow: none;
    outline: none;
}

// Form Input Selection Fixinput:
form {
    input:-webkit-autofill {
        background: rgba(120, 214, 208, 0.5)!important;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    $placeholder: #dedede;
     ::-webkit-input-placeholder {
        color: $placeholder;
    }
     :-moz-placeholder {
        /* Firefox 18- */
        color: $placeholder;
    }
     ::-moz-placeholder {
        /* Firefox 19+ */
        color: $placeholder;
    }
     :-ms-input-placeholder {
        color: $placeholder;
    }
    textarea:focus,
    input:focus,
    input[type]:focus,
    .uneditable-input:focus {
        box-shadow: none;
        background-color: none;
        outline: none;
    }
}

// Form Styles
form {
    margin: 0 0 20px;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 40px;
    color: #333333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

legend small {
    font-size: 13px;
    color: rgba(36, 36, 36, 0.8);
}

label,
input,
button,
select,
textarea {
    font-size: 13px;
    font-weight: normal;
}

label {
    display: block;
    margin-bottom: 4px;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    display: inline-block;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 13px;
    color: $dark-grey;
    vertical-align: middle;
    width: 98%;
    border-radius: 5px;
}

input,
textarea,
.uneditable-input {
    width: 98%;
    padding: 18px 12px;
}

textarea {
    height: auto;
    min-height: 100px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    outline: thin dotted \9;
    /* IE6-9 */
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    *margin-top: 0;
    /* IE7 */
    margin-top: 1px \9;
    /* IE8-9 */
    line-height: normal;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
    width: auto;
}

select,
input[type="file"] {
    height: 30px;
    /* In IE7, the height of the select element cannot be changed by height, only font-size */
    *margin-top: 4px;
    /* For IE7, add top margin to align select with labels */
    line-height: 30px;
}

select {
    width: 220px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
}

select[multiple],
select[size] {
    height: auto;
}

select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.uneditable-input,
.uneditable-textarea {
    color: rgba(36, 36, 36, 0.8);
    background-color: #fcfcfc;
    border-color: #cccccc;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    cursor: not-allowed;
}

.uneditable-input {
    overflow: hidden;
    white-space: nowrap;
}

.uneditable-textarea {
    width: auto;
    height: auto;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: rgba(36, 36, 36, 0.8);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: rgba(36, 36, 36, 0.8);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: rgba(36, 36, 36, 0.8);
}

.radio,
.checkbox {
    min-height: 20px;
    padding-left: 20px;
    @include breakpoint(from-medium) {
        display: inline-block;
        margin-right: 20px;
    }
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    float: left;
    margin-left: -20px;
}

.controls>.radio:first-child,
.controls>.checkbox:first-child {
    padding-top: 5px;
}

.radio.inline,
.checkbox.inline {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 0;
    vertical-align: middle;
}

.radio.inline+.radio.inline,
.checkbox.inline+.checkbox.inline {
    margin-left: 10px;
}

// Buttons
.btn.btn-primary,
.btn.btn-navbar {
    color: $white;
    background-color: $base2-aqua;
    border: none;
    padding: 8px 22px 8px 22px;
    height: 36px;
    @extend %transition;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0;
    position: relative;
    display: block;
    border-radius: 5px;
    &:hover {
        cursor: pointer;
        background-color: $base2-blue;
        @extend %transition;
    }
    &.fade {
        background: $light-grey;
    }
}

// Activity
i.fa-spin {
    opacity: 0;
    &.active {
        opacity: 1;
    }
}


/* line 55, /mnt/stylesheets/scss/_colour-blue.scss */

//.btn.btn-primary:hover,
//.btn.btn-navbar:hover,
//.btn.btn-primary:active,
//.btn.btn-navbar:active,
//.btn.btn-primary.active,
//.btn.btn-navbar.active,
//.btn.btn-primary.disabled,
//.btn.btn-navbar.disabled,
//.btn.btn-primary[disabled],
//.btn.btn-navbar[disabled] {
//color: #ffffff;
//background-color: $base2-blue;
//cursor: pointer;
//}
.control-label {
    margin: 8px 0 0;
    &.margin {
        margin: 20px 0;
    }
}

// Forms Page
.form-wrapper {
    @include breakpoint(from-medium) {
        @include grid(66);
        float: left;
    }
}

.addresses-wrapper {
    .social-media {
        float: none;
        text-align: left;
    }

    @include breakpoint(from-medium) {
        @include grid(25);
        float: right;
    }
}
