form.search {
    margin-top: 30px;
    margin-bottom: 30px;
    float: left;
    width: 100%;

    #tipue_search_input {
        float: left;
        width: 70%;
        height: 30px;
        margin-bottom: 0;
    }

    #search-submit {
        float: left;
        width: 30%;
        height: 30px;
        padding: 0;
        background: $base2-aqua;
        color: white;
        border: none;
        cursor: pointer;
    }
}

.well {
    font-style: italic;
    font-weight: normal;
}

// layout
.media.row-fluid {
    padding: 40px 0;
    border-bottom: 1px solid #f2f2f2;

    .tipue_search_content_loc {
        margin-top: 10px;
    }
}

//pagination
ul.pager {
    margin-left: 0 !important;
    margin-top: 30px;

    li {
        list-style: none !important;
        float: left;
        margin-right: 16px;
        height: 40px;
        width: 40px;
        line-height: 30px;
        text-align: center;
        background-color: #f2f2f2;
        border: 1px solid #ededed;

        &:last-child {
            display: none;
        }
    }
}
