$header-bar-height: 32px;

.social-media {
	padding-right: 0;
	float: right;

	a {
		color: $mid-grey;
		padding: 0 6px;
		display: inline-block;
		width: 16px;
		height: $header-bar-height;
		position: relative;
		@extend %transition;

		&:hover {
			color: $base2-aqua;
			background: none;
			text-decoration: none;
		}

		&:after {
			position: absolute;
			right: 0;
			top: 4px;
			width: 20px;
			display: block;
			height: $header-bar-height;
			line-height: $header-bar-height;
		}

		&.linkedin {
			&:after {
				@include font-awesome("\f0e1");
			}
		}
		&.twitter {
			&:after {
				top: 0;
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%23ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>');
			}
			&.contact-page {
				&:after {
					top: 0;
					content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%23989898" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>');
					width: 14px;
					left: -4px;
					top: -3px;

				}
			}
		}
		&.facebook {
			&:after {
				@include font-awesome("\f09a");
			}
		}
		&.google-plus {
			&:after {
				@include font-awesome("\f0d5");
				top: 5px;
			}
		}
		&.youtube {
			&:after {
				@include font-awesome("\f16a");
			}
		}
	}
}
p {
	abbr {
		text-decoration: none;
	}
}
// Address
p.us-address {
	margin-top: 26px;
	padding-top: 16px;
	border-top: 1px solid $light-grey;
}
a.telephone,
a.email {
	@extend %transition;
	padding-left: 24px;
	position: relative;
	font-weight: 400;
	text-decoration: none;

	&:hover {
		color: $base2-aqua;
		text-decoration: none;
	}

	&:after {
		position: absolute;
		left: 0;
		top: 3px;
		display: block;
	}
}
a.telephone {
	&:after {
		@include font-awesome("\f095");
	}
}
a.email {
	&:after {
		@include font-awesome("\f0e0");
	}
}