// Colours
$white: #fff;
$light-grey: #ededed;
$mid-grey: #989898;
$dark-grey: #464646;
$case-study-grey: #585858;
$sidebar-title-bg: #e0e0e0;
$base2-heading: #656565;
$base2-aqua: #00adbb;
$base2-green: #55a79a;
$base2-blue: #007388;
$base2-lime: rgba(171,214,163,1);
$base2-turquoise: rgba(124, 205, 204, 1);
$case-icon-blue: #00c6f2;
$case-icon-aqua: #66cdcd;
$case-icon-green: #a3d6a6;