// Partners Page
#content {
	.partner-page {
			@include breakpoint(from-medium) {
					margin-top: -30px !important;
					position: relative;
					z-index: 25;

					.sidebar {
							margin-top: 100px;
					}
			}

			.aside {
					position: relative;
			}

			// List styles
			#partner-ticks {

					li {
							list-style: none;
							margin-bottom: 6px;
							position: relative;
							margin-left: 6px;

							&:before {
									color: $base2-aqua;
									left: -26px;
									position: absolute;
									font-family: 'FontAwesome';
									content: "\f046";
									display: inline-block;
									vertical-align: middle;
							}
					}
			}
			hr {
					margin: 10px auto;
			}
			.extended-quote {
				blockquote {
					margin-top: 0;
				}
				p.small {
					color: light-grey;
					text-align: center;
					margin-top: 20px;
				}
				a {
					text-align: center;
					display: block;
					margin: 0 auto;

					i {
						font-size: 80%;
						position: relative;
						margin-left: 4px;
					}
				}
			}

			// Side bar logos
			.partners-page-logos {
				li {
					text-align: center;
					img {
						width: 70% !important;
						margin-bottom: 30px !important;
					}
					&:last-child {
						margin-bottom: 0 !important;
					}
				}
			}

			// Accordian Bullets
			.sidebar-accordion {
				.accordion-content {
					ul#accordion-bullets {

						padding-bottom: 20px;

						li {
								list-style: none;
								margin-bottom: 6px !important;
								position: relative;
								margin-left: 12px !important;

								&:before {
										color: $base2-aqua;
										left: -26px;
										top: 1px;
										position: absolute;
										font-family: 'FontAwesome';
										content: "\f00c";
										display: inline-block;
										vertical-align: middle;
								}
						}
					}
					.small-title {
						font-weight: 500;
						color: $base2-green;
					}
				}
			}

	}
}
