//Video
.video-embed-container {
  margin-bottom: 30px;
}
.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.video-embed iframe,
.video-embed object,
.video-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
* {
  .ytp-title-text {
    position: absolute !important;
    bottom: 0px !important;
    padding: 6px 12px 16px 12px !important;
    background: rgba(0,0,0,0.5) !important;
    display: block !important;
    width: 100% !important;
    left: -12px !important;
    font-weight: normal !important;
    text-transform: uppercase !important;
  }
}

// Video Modal links
.video-link {
  position: relative;
  display: block;
  background: $white;
  z-index: 11;

  .play-video {
      height: 60px;
      width: 60px;
      position: absolute;
      border: 4px solid $white;
      border-radius: 40px;
      z-index: 22;
      left: 50%;
      top: 50%;
      margin-top: -30px;
      margin-left: -30px;

      &:after {
          display: block;
          content: "\f04b";
          font-family: FontAwesome;
          color: $white;
          font-size: 22px;
          text-align: center;
          top: 16px;
          position: relative;
          left: 2px;
      }
  }

  img {
      width: 100%;
      margin-bottom: 0;
      height: auto;
  }
}

#content {
  .container {
    &.article-post {
      .video-embed-container {
        width: 100%;
        height: auto;
        display: block;

          .video-embed {
              display: inline-block;
              width: 100%;
              height: auto;
          }

          .video-info {
              display: inline-block;
              width: 100%;
              height: auto;
              padding: 12px 30px;
              margin-top: -5px;

              @include breakpoint(to-small) {
                  padding: 12px;
              }

              h4 {
                  margin-bottom: 6px;
                  margin-top: 10px;
                  color: $dark-grey;
                  font-size: 17px;
              }

              p {
                  margin-bottom: 10px;
                  color: $dark-grey;
              }

              span.right {
                  float: right;
                  color: $dark-grey;
                  font-size: 12px;
                  text-align: right;
              }
          }
        }
    }
  }
}


// Videos in three column-layout fix
.one-of-three-column-layout {
  .video-info {

      h4,
      p {
          margin-right: 0 !important;
      }
  }
}

// Video L&L Page
.video-page {
  .two-column-layout {
      width: 100%;

      .video-embed-container {
          padding-top: 0;
          padding-bottom: 0;

          @include breakpoint(to-medium) {
              margin-top: 16px;
          }

          @include breakpoint(from-medium) {
              width: calc(100% - 20px);
          }
      }
  }
}