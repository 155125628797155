// Font Awesome From CDN
// @font-face {
//   font-family: 'FontAwesome';
//   src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0');
//   src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// // helper class for font awesome icons
// .fa {
//   display: inline-block;
//   font-family: FontAwesome;
//   font-style: normal;
//   font-weight: normal;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// base2 custom icon font
@font-face {
  font-family: 'base2services';
  src: url('../font/base2services.eot?49583034');
  src: url('../font/base2services.eot?49583034#iefix') format('embedded-opentype'),
    url('../font/base2services.woff2?49583034') format('woff2'),
    url('../font/base2services.woff?49583034') format('woff'),
    url('../font/base2services.ttf?49583034') format('truetype'),
    url('../font/base2services.svg?49583034#base2services') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'base2services';
    src: url('../font/base2services.svg?49583034#base2services') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "base2services";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-bag:before {
  content: '\e800';
}

.icon-balance:before {
  content: '\e801';
}

.icon-bank:before {
  content: '\e802';
}

.icon-bar_graph_1:before {
  content: '\e803';
}

.icon-bar_graph_2:before {
  content: '\e804';
}

.icon-bookmark:before {
  content: '\e805';
}

.icon-branch_1:before {
  content: '\e806';
}

.icon-branch_2:before {
  content: '\e807';
}

.icon-calander_1:before {
  content: '\e808';
}

.icon-calander_2:before {
  content: '\e809';
}

.icon-calculator:before {
  content: '\e80a';
}

.icon-cart_1:before {
  content: '\e80b';
}

.icon-cash:before {
  content: '\e80c';
}

.icon-chair:before {
  content: '\e80d';
}

.icon-cheque:before {
  content: '\e80e';
}

.icon-clip_1:before {
  content: '\e80f';
}

.icon-clip_2:before {
  content: '\e810';
}

.icon-clock_1:before {
  content: '\e811';
}

.icon-clock_2:before {
  content: '\e812';
}

.icon-contacts:before {
  content: '\e813';
}

.icon-copy_1:before {
  content: '\e814';
}

.icon-copy_2:before {
  content: '\e815';
}

.icon-credit:before {
  content: '\e816';
}

.icon-desk:before {
  content: '\e817';
}

.icon-diary:before {
  content: '\e818';
}

.icon-download:before {
  content: '\e819';
}

.icon-edit:before {
  content: '\e81a';
}

.icon-font:before {
  content: '\e81b';
}

.icon-home_1:before {
  content: '\e81c';
}

.icon-home_2:before {
  content: '\e81d';
}

.icon-id:before {
  content: '\e81e';
}

.icon-internet:before {
  content: '\e81f';
}

.icon-library:before {
  content: '\e820';
}

.icon-link_1:before {
  content: '\e821';
}

.icon-lock:before {
  content: '\e822';
}

.icon-mail_1:before {
  content: '\e823';
}

.icon-mail_2:before {
  content: '\e824';
}

.icon-message:before {
  content: '\e825';
}

.icon-money_bag:before {
  content: '\e826';
}

.icon-paste:before {
  content: '\e827';
}

.icon-pathway:before {
  content: '\e828';
}

.icon-phone:before {
  content: '\e829';
}

.icon-pie_graph:before {
  content: '\e82a';
}

.icon-pin:before {
  content: '\e82b';
}

.icon-print_1:before {
  content: '\e82c';
}

.icon-print_2:before {
  content: '\e82d';
}

.icon-recycle:before {
  content: '\e82e';
}

.icon-report:before {
  content: '\e82f';
}

.icon-safe:before {
  content: '\e830';
}

.icon-save:before {
  content: '\e831';
}

.icon-savings:before {
  content: '\e832';
}

.icon-search_1:before {
  content: '\e833';
}

.icon-search_2:before {
  content: '\e834';
}

.icon-settings:before {
  content: '\e835';
}

.icon-stamp:before {
  content: '\e836';
}

.icon-staple:before {
  content: '\e837';
}

.icon-tag:before {
  content: '\e838';
}

.icon-unlock:before {
  content: '\e839';
}

.icon-wallet:before {
  content: '\e83a';
}

.icon-cart_2:before {
  content: '\e83b';
}

.icon-line_graph:before {
  content: '\e83c';
}

.icon-logo:before {
  content: '\e83d';
}

.icon-upload:before {
  content: '\e83e';
}

.icon-calander_3:before {
  content: '\e83f';
}

.icon-calander_5:before {
  content: '\e840';
}

.icon-calander_6:before {
  content: '\e841';
}

.icon-calander_7:before {
  content: '\e842';
}

.icon-calander_8:before {
  content: '\e843';
}

.icon-calander_9:before {
  content: '\e844';
}

.icon-calander_4:before {
  content: '\e845';
}